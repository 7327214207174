(function (angular) {
    "use strict";
    angular.module("PotatoApp")
        .factory("PotatoHarvestBuilder", HarvestBuilderFactory);

    function HarvestBuilderFactory() {
        var directory = {
            create: createHarvestSubmission
        };

        function createHarvestSubmission(plot_slug, formData, plot_area, season, unit) {
            var harvest_date = moment(formData.harvestDate).add(12, "h").hour(12).format();

            return {
                "operation": [{
                    "operation_type": "Harvest",
                    "operation_details": getOperationDetails(plot_slug, harvest_date),
                    "is_harvested_and_closed": true,
                    "products": [],
                    "crop_yield": {
                        "unit": unit,
                        "value": formData.cropyield
                    }
                }],
                "status": true,
                "growing_season": season,
                "plotsOrFields": [
                    plot_slug
                ],
                "types_of_operation": ["Harvest"],
                "equipment_details": [],
                "cost_details": [],
                "reason": "",
                "notes": "",
                "attachments": [],
                "plot_details": getPlotDetails(plot_slug, plot_area),
                "fieldplotcrops": [
                    plot_slug
                ]
            };
        }

        function getOperationDetails(plotSlug, harvestDate) {
            var operation_details = {};
            operation_details[plotSlug] = [
                {
                    "percent_completed": 100,
                    "start_datetime": harvestDate
                }
            ];
            return operation_details;
        }

        function getPlotDetails(plotSlug, plot_area) {
            var plot_details = {};
            plot_details[plotSlug] = {
                "plot": plotSlug,
                "area_of_operation": plot_area
            };
            return plot_details;
        }

        return directory;

    }

})(angular);
