
import {BurndownDateService} from '../../../angular/src/app/potato/burndown-date.service';
import {downgradeInjectable} from '@angular/upgrade/static';
import {HarvestDialogService} from '../../../angular/src/app/potato/harvest-dialog.service';

require('angulartics');

export default angular.module("PotatoApp",
    [
        'angulartics',
        require('angulartics-google-analytics'),
        "KhBase",
        "KhChart",
        "FmsApp",
        "FiltersData",
        "ngTableToCsv",
        "NiabCufApp.irrigation",
        "ApiUtils.crop.potato",
        "ApiUtils.fms.cropProgress",
        "ApiUtils.dms.cropData",
        "ApiUtils.dms.genericProducts"
    ]
);


angular.module('PotatoApp')
    .service(
        'BurndownDateService',
        downgradeInjectable(BurndownDateService)
    ).service(
        'HarvestDialogService',
        downgradeInjectable(HarvestDialogService)
    );

