angular.module('PotatoApp')
    .config(['$routeProvider', '$resourceProvider', '$translateProvider',
        function ($routeProvider, $resourceProvider, $translateProvider) {
            $resourceProvider.defaults.stripTrailingSlashes = false;

            $translateProvider.translations('en', {
                action: 'Action',
                actions: 'Actions',
                admin_dashboard: 'Admin Dashboard',
                admin_yield_digs: 'Admin Yield Digs',
                creation_failure: 'Creation failed',
                creation_success: 'Created successfully',
                dashboard: 'Dashboard',
                _delete: 'Delete',
                deletion_failed: 'Deletion failed',
                deletion_success: 'Successfully deleted',
                edit: 'Edit',
                request_failed: 'Failed to load data',
                view: 'View',
                yield_digs: 'Yield digs',
                created_successfully: 'Created successfully',
                download_user_guide: 'Download the user guide',
                updated_successfully: 'Record updated successfully',
                update_failed: 'Update failed, please refresh the page and try again.',
                user_guide: 'User guide',
                are_you_sure_to_delete_this_canopy_cover_image: 'Are you sure you want to delete this image?',
                delete_canopy_cover_image: 'Delete Canopy Cover Image'
            });

            $routeProvider
                .when('/', {
                    template: '<potato-dashboard></potato-dashboard>'
                })
                .when('/yield-dig/', {
                    controller: 'YieldDigController',
                    templateUrl: '/static/assets/khapps/crops/potato/yield_dig_assessment/YieldDig.html'
                })
                .when('/yield-digs/', {
                    controller: 'YieldDigVisualisationController',
                    templateUrl: '/static/assets/khapps/crops/potato/yield_dig_assessment/yield_dig_visualisation/YieldDigVisualisation.html',
                    admin: false
                })
                .when('/scenario-analysis/', {
                    controller: 'BurndownModellingCtrl',
                    templateUrl: '/static/assets/khapps/crops/potato/burndown_modelling/BurndownModellingComponent.html'
                })
                .when('/irrigation-scheduling/', {
                    controller: 'IrrigationSchedulingTableController',
                    templateUrl: '/static/assets/khapps/niabcuf/irrigation/partials/irrigation_scheduling.html'
                })
                .when('/irrigation-summary/', {
                    controller: 'IrrigationSummaryController',
                    templateUrl: '/static/assets/khapps/niabcuf/irrigation/partials/IrrigationSummary.html'
                })
                .when('/admin/', {
                    controller: 'PotatoAdminSummaryController',
                    templateUrl: '/static/assets/khapps/crops/potato/admin/PotatoAdminSummary.html',
                    admin: true
                })
                .when('/admin/:team_slug', {
                    template: '<potato-dashboard></potato-dashboard>',
                    admin: true
                })
                .when('/admin/:team_slug/yield-digs/', {
                    controller: 'YieldDigVisualisationController',
                    templateUrl: '/static/assets/khapps/crops/potato/yield_dig_assessment/yield_dig_visualisation/YieldDigVisualisation.html',
                    admin: true
                })
                .otherwise({
                    redirectTo: '/'
                });
        }
    ]);
