(function (angular) {
    'use strict';
    angular.module('PotatoApp')
        .controller('VarietyUpdateController', ['$scope', '$mdDialog', '$khMessage', '$filter',
            'plot_summary', 'PotatoPlotOperations', VarietyUpdateController]);

    function VarietyUpdateController($scope, $mdDialog, $khMessage, $filter, plot_summary,
                                     PotatoPlotOperations) {

        var vm = this;
        var parentScope = $scope;
        $scope.plot_slug = plot_summary.plot_slug;
        $scope.submitVarietyUpdate = submitVarietyUpdate;

        PotatoPlotOperations.query({slug: plot_summary.plot_slug}, function (success) {
            var plot = success['results'][0];
            $scope.planting_variety_form = plantingVarietyForm(plot.variety_id, $scope.potato_varieties);
            $scope.plantingVarietyDetail = {};
        }, function (error) {
            $khMessage.show('Failed to get Plot', 5000);
        });

        function plantingVarietyForm(plot_variety_id, potato_varieties) {
            return [
                {
                    className: "flex-100",
                    key: "variety",
                    type: "searchable_select",
                    defaultValue: plot_variety_id ? plot_variety_id : null,
                    templateOptions: {
                        label: "Variety",
                        required: true,
                        options: potato_varieties,
                        valueProp: 'id',
                        lableProp: 'name'
                    }
                }
            ];
        }

        function submitVarietyUpdate(plot_slug, formData) {
            PotatoPlotOperations.query({slug: plot_slug}, function (query_result) {
                var plantingSubmission = query_result['results'][0];
                var plot_id = plantingSubmission['id'];
                delete plantingSubmission['id'];
                plantingSubmission['variety_id'] = formData.variety;
                if (plantingSubmission['planting']['date'] === null) {
                    delete plantingSubmission['planting'];
                }
                if (plantingSubmission['harvest']['date'] === null && plantingSubmission['harvest']['yield'] === null) {
                    delete plantingSubmission['harvest'];
                }
                PotatoPlotOperations.update({plot_id: plot_id}, plantingSubmission, function(update_result){
                    update_result.plot_slug = plot_slug;
                    var data = {'variety_id': formData.variety, 'plot_slug': plot_slug};
                    $mdDialog.hide(data);
                }, function (error) {
                    $khMessage.show('Failed to record planting details', 5000);
                });
            }, function (error) {
                $khMessage.show('Failed to get Plot', 5000);
            });
        }

    }

})(angular);
