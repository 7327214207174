(function (angular) {
    'use strict';
    angular.module('PotatoApp')
        .controller('PotatoHarvestController', ['$scope', '$mdDialog', '$khMessage', '$filter',
            'plot_slug', 'burndown_date_string', 'harvest_date', 'actual_yield',
            'PotatoPlotOperations', PotatoHarvestController]);

    function PotatoHarvestController($scope, $mdDialog, $khMessage, $filter,
                                     plot_slug, burndown_date_string, harvest_date, actual_yield,
                                     PotatoPlotOperations) {
        var vm = this;
        $scope.plot_slug = plot_slug;
        $scope.burndown_date_string = burndown_date_string;
        $scope.harvest_date_form = potatoHarvestForm(harvest_date, actual_yield);
        $scope.harvestDetail = {};
        $scope.submitHarvest = submitHarvest;

        function potatoHarvestForm(harvest_date, actual_yield) {
            return [
                {
                    className: 'flex-100',
                    key: 'harvestDate',
                    type: 'datepicker',
                    defaultValue: harvest_date ? harvest_date : null,
                    templateOptions: {
                        label: 'Harvest date',
                        required: true,
                        minDate: new Date(burndown_date_string),
                    }
                },
                {
                    className: 'flex-100',
                    key: 'cropyield',
                    type: 'input',
                    defaultValue: actual_yield ? actual_yield : null,
                    templateOptions: {
                        required: true,
                        label: 'Actual Yield (t/ha)'
                    }
                }
            ];
        }

        function submitHarvest(plot_slug, formData) {
            var harvest_date = moment(formData.harvestDate).add(12, 'h').hour(12).format();
            PotatoPlotOperations.query({slug: plot_slug}, function (success) {
                var harvestSubmission = success['results'][0];
                var plot_id = harvestSubmission['id'];
                delete harvestSubmission['id'];
                harvestSubmission['actual_harvest_date'] = harvest_date;
                harvestSubmission['actual_yield'] = formData.cropyield ? formData.cropyield : null;
                if (harvestSubmission['harvest']['date'] === null && harvestSubmission['harvest']['yield'] === null) {
                    delete harvestSubmission['harvest'];
                }

                PotatoPlotOperations.update({plot_id: plot_id}, harvestSubmission, function (success) {
                    var data = {
                        'plot_slug': plot_slug,
                        'harvest_date_string': moment(harvest_date).format('DD MMM YYYY'),
                        'harvest_date': harvest_date,
                        'harvest_op_status': true,
                        'harvest_actual_status': false,
                        'harvest_status_description': 'Record',
                        'harvest_actual_yield': formData.cropyield
                    };
                    $mdDialog.hide(data);
                }, function (error) {
                    $khMessage.show('Failed to record harvest details', 5000);
                });
            }, function (error) {
                $khMessage.show('Failed to get Plot', 5000);
            });
        }

    }

})(angular);
