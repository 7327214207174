(function (angular) {
    "use strict";
    angular.module('PotatoApp')
        .controller('BurndownModellingCtrl', BurndownModellingController);

    BurndownModellingController.$inject = ['$scope', '$rootScope', '$filter', '$mdDialog', '$khMessage', 'NgTableParams', 'BurndownModellingForm', 'BurndownModellingScenariosForm', 'BurndownModellingHelpers', 'PotatoBurndownModellingYieldCharts', 'BurndownModellingRevenueCharts'];

    function BurndownModellingController($scope, $rootScope, $filter, $mdDialog, $khMessage, NgTableParams, BurndownModellingForm, BurndownModellingScenariosForm, BurndownModellingHelpers, PotatoBurndownModellingYieldCharts, BurndownModellingRevenueCharts) {
        var vm = this;

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Initialisation and configuration
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        initialiseController();

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Controller API
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        $scope.updateModelOutput = updateModelOutput;
        $scope.updateRevenue = updateAllRevenueValues;
        $scope.revenueStatus = revenueStatus;

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Internal API: Function definitions
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        function initialiseController() {
            $rootScope.page_id = "potatodashboard";
            $rootScope.sub_app_page_id = "potatodashboard_scenario";
            //$rootScope.currentNavItem = "";
            $scope.modelOutput = null;
            $scope.priceAssumptions = {};
            $scope.burndownFormModel = {};
            $scope.burndownModellingFields = [];
            BurndownModellingForm.generateForm($rootScope, $scope);
        }

        function updateModelOutput(formData) {
            clearYieldCharts();
            BurndownModellingForm.retrieveModelOutput(formData.model_run,
                function (success) {
                    var dateExtremes = BurndownModellingHelpers.extractDateExtremes(success);
                    var defaultDateExtremes = BurndownModellingHelpers.extractDefaultDateExtremes(success);
                    initialiseYieldCharts(success, defaultDateExtremes);
                    initialiseScenariosForm(dateExtremes, defaultDateExtremes);
                    $scope.modelOutput = success;
                },
                function (error) {
                    $khMessage.show("It was not possible to retrieve this model output. Please refresh and try again.", 5000)
                }
            )
        }

        function updateAllRevenueValues() {
            $scope.revenueDistributions = updateRevenueDistributions($scope.chartData, $scope.priceAssumptions);
            $scope.revenueTotals = updateTotalRevenues($scope.revenueDistributions);
            updateRevenueCharts($scope.revenueDistributions, $scope.revenueTotals, $scope.chartData.partitionLabels);
        }

        function updateExistingCharts(chartData) {
            updateTotalYieldChart(chartData);
            updateYieldDistributionChart(chartData);
            if ($scope.priceAssumptions) {
                updateRevenueCharts()
            }
        }

        /////////////////////////////////////////
        // YIELD
        /////////////////////////////////////////
        function clearYieldCharts() {
            $scope.modelOutput = null;
            $scope.YieldModelChartConfig = null;
            $scope.YieldDistributionChartConfig = null;
        }

        function initialiseYieldCharts(modelOutput, dateExtremes) {
            $scope.chartData = BurndownModellingHelpers.extractChartDataFromModelOutput(modelOutput, dateExtremes[0], dateExtremes[1]);
            refreshChartsWithNewOutput($scope.chartData);
        }

        function initialiseScenariosForm(dateExtremes, defaultDateExtremes) {
            $scope.scenariosModel = {};
            $scope.scenariosFields = BurndownModellingScenariosForm.fetch(dateExtremes, defaultDateExtremes);
        }

        function refreshChartsWithNewOutput(chartData) {
            $scope.YieldModelChartConfig = PotatoBurndownModellingYieldCharts.getYieldModelChartConfig(chartData.totalYields, chartData.scenarioOneDate, chartData.scenarioTwoDate);
            $scope.YieldDistributionChartConfig = PotatoBurndownModellingYieldCharts.getYieldDistributionChartConfig(chartData.scenarioOnePartitions, chartData.scenarioTwoPartitions, chartData.partitionLabels);
        }

        function updateTotalYieldChart(chartData) {
            var YieldModelChartConfig = PotatoBurndownModellingYieldCharts.getYieldModelChartConfig(chartData.totalYields, chartData.scenarioOneDate, chartData.scenarioTwoDate);
            $scope.YieldModelChartConfig.series = YieldModelChartConfig.series;
        }

        function updateYieldDistributionChart(chartData) {
            var YieldDistributionChartConfig = PotatoBurndownModellingYieldCharts.getYieldDistributionChartConfig(chartData.scenarioOnePartitions, chartData.scenarioTwoPartitions, chartData.partitionLabels);
            $scope.YieldDistributionChartConfig.series = YieldDistributionChartConfig.series;
        }

        /////////////////////////////////////////
        // REVENUE
        /////////////////////////////////////////
        function updateRevenueDistributions(chartData, priceAssumptions){
            return BurndownModellingRevenueCharts.revenueDistributions(chartData, priceAssumptions)
        }

        function updateTotalRevenues(scenarioRevenueDistributions) {
            return BurndownModellingRevenueCharts.totalRevenues(scenarioRevenueDistributions);
        }

        function updateRevenueDistributionsChart(partitionLabels, revenueDistributions) {
            if (!$scope.RevenueDistributionsChart) {
                $scope.RevenueDistributionsChart = BurndownModellingRevenueCharts.revenueDistributionChart(partitionLabels, revenueDistributions);
            } else {
                var revenueDistributionsChartConfig = BurndownModellingRevenueCharts.revenueDistributionChart(partitionLabels, revenueDistributions);
                $scope.RevenueDistributionsChart.series = revenueDistributionsChartConfig.series;
            }
        }

        function updateRevenueTotalsChart(revenueTotals) {
            if (!$scope.RevenueTotalsChart) {
                $scope.RevenueTotalsChart = BurndownModellingRevenueCharts.revenueTotalsChart(revenueTotals);
            } else {
                var RevenueTotalsChartConfig = BurndownModellingRevenueCharts.revenueTotalsChart(revenueTotals);
                $scope.RevenueTotalsChart.series = RevenueTotalsChartConfig.series;
            }
        }

        function updateRevenueCharts(revenueDistributions, revenueTotals, partitionLabels){
            updateRevenueDistributionsChart(partitionLabels, revenueDistributions);
            updateRevenueTotalsChart(revenueTotals);
        }

        function revenueStatus (priceAssumptions) {
            return BurndownModellingRevenueCharts.revenueStatus(priceAssumptions)
        }

        function getTimeTupleFromDate(scenarioInputDate) {
            var utcScenarioInputDate = moment.utc([scenarioInputDate.getUTCFullYear(), scenarioInputDate.getUTCMonth(), scenarioInputDate.getUTCDate(), 0, 0]);
            var scenarioTimestamp = utcScenarioInputDate.unix();
            return scenarioTimestamp * 1000
        }

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Watchers and Filter routines
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        $scope.$on('$destroy', function () {
            onSecNavFilterUpdate();
            watchFpcChange();
            watchScenarioDates();
        });
        var onSecNavFilterUpdate = $scope.$on("filter:selections_updated",
            function () {
                initialiseController()
            }
        );
        var watchFpcChange = $scope.$watch("burndownFormModel.field_plot_crop", function (newval, oldval) {
            $scope.modelOutput = null;
        });

        var watchScenarioDates = $scope.$on("scenarioChange", function (event) {
            var scenarioOneDate = getTimeTupleFromDate($scope.scenariosModel.scenario_1);
            var scenarioTwoDate = getTimeTupleFromDate($scope.scenariosModel.scenario_2);
            $scope.chartData = BurndownModellingHelpers.extractChartDataFromModelOutput($scope.modelOutput, scenarioOneDate, scenarioTwoDate);
            updateAllRevenueValues();
            updateExistingCharts($scope.chartData);
        });

    }

})(angular);
