(function (angular) {
    'use strict';
    angular.module('PotatoApp')
        .factory('CanopyCoverImageFactory', CanopyCoverImageFactory);

    function CanopyCoverImageFactory($resource) {
        var url = '/api/v1.0/crop-monitoring/image-based/crop-progress/';
        return $resource(url, {},
            {
                query: {
                    url: url,
                    isArray: true
                },
                update: {
                    url: url + ':crop_progress_slug/',
                    method: 'PATCH'
                }
            }
        );

    }

})(angular);