(function (angular) {
    "use strict";
    angular.module('PotatoApp')
        .factory('PotatoBurndownBuilder', BurndownBuilderFactory);

    function BurndownBuilderFactory() {
        var directory = {
            create: createBurndownSubmission
        };

        function createBurndownSubmission(plot_slug, formData, plot_area, season) {
            var burndown_date = moment(formData.burndownDate).add(12, 'h').hour(12).format();

            return {
                "operation": [{
                    "operation_type": "Burn Down",
                    "products": [
                        getProductDetails(formData)
                    ],
                    "hideProducts": false,
                    "operation_details": getOperationDetails(plot_slug, burndown_date)
                }],
                "status": true,
                "growing_season": season,
                "plotsOrFields": [
                    plot_slug
                ],
                "types_of_operation": ["Burn Down"],
                "equipment_details": [],
                "cost_details": [],
                "notes": "",
                "reason": "",
                "attachments": [],
                "plot_details": getPlotDetails(plot_slug, plot_area),
                "fieldplotcrops": [
                    plot_slug
                ]
            };

        }

        function getProductDetails(formData) {
            if (formData.product) {
                return {
                    "product_type": "Herbicide",
                    "product": formData.product,
                    "product_name": ""
                }
            } else {
                return {
                    "product_type": "Herbicide",
                    "product_name": "n/a"
                }
            }
        }

        function getOperationDetails(plotSlug, burndownDate) {
            var operation_details = {};
            operation_details[plotSlug] = [
                {
                    "percent_completed": 100,
                    "start_datetime": burndownDate
                }
            ];
            return operation_details;
        }

        function getPlotDetails(plotSlug, plot_area) {
            var plot_details = {};
            plot_details[plotSlug] = {
                "plot": plotSlug,
                "area_of_operation": plot_area
            };
            return plot_details;
        }

        return directory
    }

})(angular);
