(function (angular) {
    "use strict";
    angular.module('PotatoApp')
        .factory('EmergenceProgressFactory', EmergenceProgressFactory);

    function EmergenceProgressFactory($resource) {
        var url = "api/potato/emergence-summary/";
        var queryParamDict = {
            farm: '@farm'
        };

        return $resource(url, {},
            {
                get: {
                    params: queryParamDict,
                    url: url
                }
            }
        );

    }

})(angular);