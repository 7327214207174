//
// Note this is a directive and not a component due to the large number of sub-templated that rely on parent $scope
//
// This is obviously not ideal
//
angular.module('PotatoApp').directive('potatoDashboard', function () {
    return {
        restrict: 'E',
        templateUrl: '/static/assets/khapps/crops/potato/dashboard/potato-dashboard.component.html',
        controller: function ($scope,
                              $rootScope,
                              $route,
                              $routeParams,
                              $mdDialog,
                              $filter,
                              PlantVarietyListFactory,
                              FarmFiltersService,
                              MileStoneSummaryFactory,
                              PlantingProgressFactory,
                              EmergenceProgressFactory,
                              CanopyCoverFactory,
                              PotatoOperations,
                              HarvestProgressFactory,
                              NgTableParams,
                              YieldForecastSummary,
                              PotatoAdminDataLoader,
                              BurndownDateService,
                              HarvestDialogService,
                              PotatoPlantingSystems,
                              $khMessage
        ) {

            let currentFarmFieldFilter;
            let selectedVariety;
            $scope.adminStatus = $route.current.$$route.admin;
            $rootScope.page_id = 'potatodashboard'; // the parent app id
            if ($scope.adminStatus) {
                $rootScope.sub_app_page_id = 'potatodashboard_admin';
                $rootScope.displayAdminNavLinks = true;
                $rootScope.view_only_irrigation = false;
                $rootScope.currentNavItem = 'dashboardAdmin';
                $rootScope.teamSlug = $routeParams.team_slug;
            } else {
                $rootScope.sub_app_page_id = null;
                $rootScope.view_only_irrigation = false;
                $rootScope.currentNavItem = 'dashboard';
            }

            // reload the source data and refresh the graphs
            $scope.reload = function () {
                $scope.mileStoneSummaryTableData = new NgTableParams({}, {dataset: []});
                $scope.emergenceAPIData = null;
                $scope.canopyCoverAPIData = null;
                $scope.yieldForecastAPIData = null;
                loadPotatoDashboardAPIsData();
            };

            let sub = FarmFiltersService.get$().subscribe((filter) => {
                if (filter) {
                    currentFarmFieldFilter = filter;
                    $scope.reload();
                }
            });

            $scope.$on('$destroy', function () {
                sub.dispose();
            });

            $scope.varietyChanged = function (variety) {
                selectedVariety = (variety && variety !== 'All Varieties') ? variety : null;
                $scope.reload();
            };

            $scope.updatePlantingVariety = updatePlantingVariety;
            $scope.updatePlantingForm = updatePlantingForm;

            $scope.showPlantingConfigForm = showPlantingConfigForm;

            $scope.showEmergenceForm = showEmergenceForm;
            $scope.editEmergenceForm = editEmergenceForm;

            $scope.showBurndownForm = showBurndownForm;
            $scope.showHarvestForm = showHarvestForm;
            $scope.showProgress = false;

            defineMileStoneSummryTableFilters();


            function defineMileStoneSummryTableFilters() {
                $scope.plotFilterConfig = {'plot_name': {id: 'text', placeholder: 'Filter by name'}};
                $scope.plotVarietyFilterConfig = {'plot_variety': {id: 'text', placeholder: 'Filter by name'}};
                $scope.plantingStatusFilterConfig = {
                    'planting_status_description': {id: 'text', placeholder: 'Filter by status'}
                };
                $scope.plantingConfigStatusFilterConfig = {
                    'planting_config_status': {id: 'text', placeholder: 'Filter by status'}
                };
                $scope.plantingFilterConfig = {'planting_date_string': {id: 'text', placeholder: 'Filter by value'}};
                $scope.emergenceFilterConfig = {'emergence_date_string': {id: 'text', placeholder: 'Filter by value'}};
                $scope.burndownStatusFilterConfig = {
                    'burndown_status_description': {id: 'text', placeholder: 'Filter by status'}
                };
                $scope.burndownDateFilterConfig = {
                    'burndown_date_string': {
                        id: 'text',
                        placeholder: 'Filter by value'
                    }
                };
                $scope.harvestStatusFilterConfig = {
                    'harvest_status_description': {id: 'text', placeholder: 'Filter by status'}
                };
                $scope.harvestDateFilterConfig = {'harvest_date_string': {id: 'text', placeholder: 'Filter by value'}};
                $scope.yieldDigFilterConfig = {'yield_dig_date_string': {id: 'text', placeholder: 'Filter by value'}};
                $scope.uuidFilterConfig = {'plot_slug': {id: 'text', placeholder: 'Filter by value'}};
            }

            function loadPotatoDashboardAPIsData() {
                if ($scope.adminStatus) {
                    var teamSlug = $routeParams.team_slug;
                    PotatoAdminDataLoader.loadAdminMilestoneSummaryData($scope, teamSlug);
                    PotatoAdminDataLoader.loadAdminEmergenceSummaryData($scope, teamSlug);
                    PotatoAdminDataLoader.loadAdminCanopySummaryData($scope, teamSlug);
                    PotatoAdminDataLoader.loadAdminYieldForecastSummaryData($scope, teamSlug);
                } else {
                    // Data not returned from any of the API should display an appropriate toast message and should not
                    // prevent data from the other APIs to be displayed.

                    MileStoneSummaryFactory.query(getMileStoneSummaryAPIQueryParam()).$promise.then(function (result) {
                            loadMileStoneSummaryAPIData(result);
                        },
                        function () {
                            $khMessage.show('Could not fetch Milestone Summary data. Please contact support team.')
                        });
                    EmergenceProgressFactory.get(getEmergenceProgressAPIQueryParam()).$promise.then(function (result) {
                            loadEmergenceAPIData(result);
                        },
                        function () {
                            $khMessage.show('Could not fetch Emergence Progress data. Please contact support team.')
                        });
                    CanopyCoverFactory.query(getCanopyCoverAPIQueryParam()).$promise.then(function (result) {
                            loadCanopyCoverAPIData(result);
                        },
                        function () {
                            $khMessage.show('Could not fetch Ground Cover data. Please contact support team.')
                        });
                    YieldForecastSummary.query(getYieldForecastAPIQueryParam()).$promise.then(function (result) {
                            loadYieldAPIData(result);
                        },
                        function () {
                            $khMessage.show('Could not fetch Yield Forecast data. Please contact support team.')
                        });

                    PlantVarietyListFactory.query({crop_name: 'Potato'}, function (success) {
                        $scope.potato_varieties = success;
                    }, function (error) {
                        console.log(error);
                    });

                    PotatoPlantingSystems.query({}, function (success) {
                        $scope.potato_planting_systems = success;
                    }, function (error) {
                        console.log(error);
                    });

                    declareAPIScopeVariables();

                    if ($scope.showProgress) {
                        loadPlantingProgressAPIData();
                        loadHarvestAPIData();
                    }
                }
            }

            function loadMileStoneSummaryAPIData(milestone_summary) {
                // planting_datetime gives datetime in UTC. converting it into local date string
                angular.forEach(milestone_summary, function (row) {
                    if (row['planting_datetime']) {
                        row['planting_date_string'] = moment(new Date(row['planting_datetime'] + '+00:00')).format('DD MMM YYYY');
                    }
                });
                $scope.showEmptyState = milestone_summary.length > 0 ? false : true;
                $scope.mileStoneSummaryTableData = new NgTableParams({count: 20}, {
                    counts: [20, 50, 100, 200],
                    dataset: milestone_summary
                });
            }

            function getMileStoneSummaryAPIQueryParam() {
                return getBaseQueryParams();
            }

            function loadPlantingProgressAPIData() {
                $scope.plantingProgressAPIData = null;
                PlantingProgressFactory.get(getPlantingProgressAPIQueryParam(), function (plantingProgressAPIData) {
                    $scope.plantingProgressAPIData = plantingProgressAPIData;
                }, function (error) {
                    $scope.plantingProgressAPIData = null;
                });

            }

            function loadEmergenceAPIData(emergence_summary) {
                $scope.emergenceAPIData = null;
                $scope.emergenceAPIData = emergence_summary;
            }

            function loadCanopyCoverAPIData(canopy_summary) {
                $scope.canopyCoverAPIData = null;
                $scope.canopyCoverAPIData = canopy_summary;
            }

            function loadHarvestAPIData() {
                $scope.harvestProgressAPIData = null;
                HarvestProgressFactory.get(getHarvestProgressAPIQueryParam(), function (harvestProgressAPIData) {
                    $scope.harvestProgressAPIData = harvestProgressAPIData;
                }, function (error) {
                    $scope.harvestProgressAPIData = null;
                });
            }

            function loadYieldAPIData(forecast_summary) {
                $scope.yieldForecastAPIData = null;
                $scope.yieldForecastAPIData = forecast_summary;
            }

            function getBaseQueryParams() {
                return {
                    'farm': currentFarmFieldFilter.farm_slug,
                    'growing_season': currentFarmFieldFilter.growing_season,
                    'team_slug': currentFarmFieldFilter.team_slug === null ? 'All Teams' : currentFarmFieldFilter.team_slug,
                    'variety_slug': (selectedVariety) ? selectedVariety.slug : null
                };
            }

            function getCanopyCoverAPIQueryParam() {
                return getBaseQueryParams();
            }

            function getYieldForecastAPIQueryParam() {
                return getBaseQueryParams();
            }


            function getPlantingProgressAPIQueryParam() {
                var params = getBaseQueryParams();
                params['noofseasons'] = 2;
                return params;
            }


            function getEmergenceProgressAPIQueryParam() {
                return getBaseQueryParams();
            }

            function getHarvestProgressAPIQueryParam() {
                var params = getBaseQueryParams();
                params['noofseasons'] = 2;
                return params;
            }

            function updatePlantingVariety(plot_summary) {
                $mdDialog.show({
                    templateUrl: '/static/assets/khapps/crops/potato/dashboard/milestonesummary/forms/planting_update/planting_variety_update_form.html',
                    controller: 'VarietyUpdateController',
                    scope: $scope,
                    preserveScope: true,
                    locals: {
                        plot_summary: plot_summary
                    }
                }).then(function (data) {
                    var new_variety = _.filter($scope.potato_varieties, function (el) {
                        return el.id === data.variety_id;
                    });
                    angular.forEach($scope.mileStoneSummaryTableData.data, function (element, index) {
                        if (element.plot_slug === data.plot_slug) {
                            element.plot_variety = new_variety[0].name;
                            element.plot_variety_slug = new_variety[0].slug;
                        }
                    });
                });
            }

            function updatePlantingForm(plot_summary) {
                $mdDialog.show({
                    templateUrl: '/static/assets/khapps/crops/potato/dashboard/milestonesummary/forms/planting_update/potato_planting_update_form.html',
                    controller: 'PotatoPlantingUpdateController',
                    scope: $scope,
                    preserveScope: true,
                    locals: {
                        plot_summary: plot_summary
                    }
                }).then(function (data) {
                    angular.forEach($scope.mileStoneSummaryTableData.data, function (element, index) {
                        if (element.plot_slug === data.plot_slug) {
                            element.planting_status_description = data.planting_status_description;
                            element.planting_date_string = data.planting_date_string;
                            element.planting_datetime = moment(data.planting_date).format('YYYY-MM-DDTHH:mm:SS');
                            element.planting_status = true;
                        }
                    });
                });
            }

            function showPlantingConfigForm(plot_slug, planting_config_slug) {
                $mdDialog.show({
                    templateUrl: '/static/assets/khapps/crops/potato/dashboard/milestonesummary/forms/planting_configuration/potato_planting_config_form.html',
                    controller: 'PotatoPlantingConfigurationController',
                    scope: $scope,
                    preserveScope: true,
                    locals: {
                        plot_slug: plot_slug,
                        planting_config_slug: planting_config_slug,
                    }
                }).then(function (data) {
                    var new_planting_config = _.filter($scope.potato_planting_systems, function (el) {
                        return el.slug === data.planting_system;
                    });
                    angular.forEach($scope.mileStoneSummaryTableData.data, function (element, index) {
                        if (element.plot_slug === data.fieldplotcrop) {
                            element.planting_config = new_planting_config[0].name;
                            element.planting_config_slug = new_planting_config[0].slug;
                            element.planting_config_status = true;
                        }
                    });
                });
            }

            function get_emergence_template(emergence_type) {
                if (emergence_type === 'FIRST_EMERGENCE') {
                    return '/static/assets/khapps/crops/potato/dashboard/milestonesummary/forms/emergence/temp_first_emergence_form.html';
                } else {
                    return '/static/assets/khapps/crops/potato/dashboard/milestonesummary/forms/emergence/temp_emergence_form.html';
                }
            }

            function showEmergenceForm(plot_slug, emergence_type, min_date) {
                $mdDialog.show({
                    templateUrl: get_emergence_template(emergence_type),
                    controller: 'TempEmergenceController',
                    scope: $scope,
                    preserveScope: true,
                    locals: {
                        plot_slug: plot_slug,
                        emergence_type: emergence_type,
                        min_date: min_date
                    }
                }).then(function (data) {
                    angular.forEach($scope.mileStoneSummaryTableData.data, function (element, index) {
                        if (element.plot_slug === data.fieldplotcrop) {
                            if (emergence_type === 'FIRST_EMERGENCE') {
                                element.first_emergence_date_string = data.emergence_date_string;
                                element.first_emergence_date_actual = data.emergence_date;
                                element.emerged_first_status = true;
                                element.emerged_first_id = data.emergence_record_id;
                            }
                            if (emergence_type === 'FIFTY_PERCENT_EMERGENCE') {
                                element.emergence_date_string = data.emergence_date_string;
                                element.emergence_date_actual = data.emergence_date;
                                element.emerged_50_status = true;
                                element.emerged_fifty_id = data.emergence_record_id;
                            }
                        }
                    });
                });
            }

            function editEmergenceForm(plot_slug, emergence_record_id, existing_date, emergence_type, min_date) {
                $mdDialog.show({
                    templateUrl: get_emergence_template(emergence_type),
                    controller: 'EditTempEmergenceController',
                    scope: $scope,
                    preserveScope: true,
                    locals: {
                        plot_slug: plot_slug,
                        emergence_record_id: emergence_record_id,
                        emergence_type: emergence_type,
                        existing_date: existing_date,
                        min_date: min_date
                    }
                }).then(function (data) {
                    angular.forEach($scope.mileStoneSummaryTableData.data, function (element, index) {
                        if (element.plot_slug === data.fieldplotcrop) {
                            if (emergence_type === 'FIRST_EMERGENCE') {
                                element.first_emergence_date_string = data.emergence_date_string;
                                element.first_emergence_date_actual = data.emergence_date;
                                element.emerged_first_status = true;
                            }
                            if (emergence_type === 'FIFTY_PERCENT_EMERGENCE') {
                                element.emergence_date_string = data.emergence_date_string;
                                element.emergence_date_actual = data.emergence_date;
                                element.emerged_50_status = true;
                            }
                        }
                    });
                });
            }

            function getPlotRow(plot_id) {
                return _.findWhere($scope.mileStoneSummaryTableData.data, {plot_id:plot_id});
            }

            function showBurndownForm(plot_id) {
                let row = getPlotRow(plot_id);
                BurndownDateService.open(plot_id, row.burndown_date, row.burndown_product_id).subscribe( op => {
                    row.burndown_date = op.start_date;
                    if (op.products) {
                        row.burndown_product_id = op.products[0].product_id;
                    }
                    $scope.mileStoneSummaryTableData.reload();
                });
            }

            function showHarvestForm(plot_id) {
                let row = getPlotRow(plot_id);
                HarvestDialogService.open(plot_id, row.harvest_date, row.harvest_actual_yield).subscribe( plot => {
                    row.harvest_date = plot.actual_harvest_date;
                    row.harvest_actual_yield = plot.actual_yield;
                    $scope.mileStoneSummaryTableData.reload();
                });
            }

            $scope.plantingScatterTooltipFormatterFunction = function () {
                var day_of_year = this.point.day_of_year;
                return '<b>' + this.point.plot_name + '</b><br> Day of year: ' + day_of_year;

            };

            $scope.harvestScatterTooltipFormatterFunction = function () {
                return '<b>' + this.point.plot_name + '</b><br> Days after planting: ' + this.point.day_of_year;
            };

            $scope.canopyCoverScatterTooltipFormatterFunction = function () {
                var deviation = this.point.deviation;
                if (deviation >= 0) {
                    return '<b>' + this.point.plot_name + '</b><br>' + Math.abs(deviation) + ' % more than benchmark';
                } else {
                    return '<b>' + this.point.plot_name + '</b><br>' + Math.abs(deviation) + ' % less than benchmark';
                }
            };

            $scope.absoluteCanopyCoverScatterTooltipFormatterFunction = function () {
                return '<b>' + this.point.plot_name + '</b><br>' + Math.abs(this.point.ground_cover) + ' %';
            };

            $scope.emergenceScatterTooltipFormatterFunction = function () {
                return '<b>' + this.point.plot_name + '</b><br>Emergence: ' + this.point.day_of_year + ' days after planting';
            };

            $scope.yieldForecastScatterTooltipFormatterFunction = function () {
                return '<b>' + this.point.plot_name + '</b><br>Yield: ' + $filter('number')(this.point.yield_forecast, 1) + ' t/ha';
            };

            $scope.tuberPopulationScatterTooltipFormatterFunction = function () {
                return '<b>' + this.point.plot_name + '</b><br>Tuber population: ' + this.point.tuber_population + 'k per ha';
            };


            function declareAPIScopeVariables() {
                $scope.plantingProgressAPIData = null;
                $scope.harvestProgressAPIData = null;
                $scope.canopyCoverAPIData = null;
            }

            $scope.showPotatoPlotDetail = function (plotData) {
                $mdDialog.show({
                    controller: 'PotatoCropDetailController',
                    skipHide: true,
                    multiple: true,
                    templateUrl: '/static/assets/khapps/crops/potato/dashboard/crop_detail/PotatoCropDetailDialogue.html',
                    locals: {
                        parentControllerData: {'plotData': plotData}
                    },
                    clickOutsideToClose: true
                });
            };
        }
    }
});
