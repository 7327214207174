(function (angular) {
    "use strict";
    angular.module('PotatoApp')
        .controller('PotatoAdminSummaryController',
            [
                "$scope",
                "$rootScope",
                "NgTableParams",
                "PotatoAdminDataLoader",
                PotatoAdminSummaryController
            ]
        );


    function PotatoAdminSummaryController($scope,
                                          $rootScope,
                                          NgTableParams,
                                          PotatoAdminDataLoader) {

        $rootScope.page_id = "potatodashboard"; // the parent app id
        $rootScope.sub_app_page_id = "potatodashboard_admin";
        $rootScope.displayNavLinks = false;
        $rootScope.view_only_irrigation = false;

        $scope.teamNameFilterConfig = {'team_name': {id: 'text', placeholder: 'Filter by team name'}};

        $scope.$on('$destroy', function () {
            onSecNavFilterUpdate();
            onRefreshPage();
        });
        var onSecNavFilterUpdate = $scope.$on("filter:selections_updated",
            function () {
                loadAdminSummary();
            }
        );
        var onRefreshPage = $scope.$on('$routeChangeSuccess',
            function (scope, next, previous) {
                if (previous) {
                    loadAdminSummary();
                }
            }
        );

        function loadAdminSummary() {
            var selected_growing_season = $rootScope.selected_growing_season.value;
            PotatoAdminDataLoader.loadAdminSummaryData($scope, selected_growing_season, function (scope, data) {
                scope.adminSummaryApiData = data;
                scope.showEmptyState = data.length > 0 ? false : true;
                scope.adminSummaryTable = new NgTableParams({count: 10}, {
                    counts: [10, 15, 20, 50],
                    dataset: data
                });
                scope.totalsData = totalsDataFromSummary(data);
            });
        }

        function totalsDataFromSummary(apiData) {
            var total_crops = 0;
            var total_area = 0;
            var total_canopy = 0;
            var total_yieldModel = 0;
            var total_irrigation = 0;
            angular.forEach(apiData, function (element) {
                total_crops += element.potato_crop_count;
                total_area += element.cropped_area;
                total_canopy += element.canopy_cover;
                total_yieldModel += element.yield_model;
                total_irrigation += element.irrigation;
            });
            return {
                total_crops: total_crops,
                total_area: total_area,
                total_canopy: total_canopy,
                total_yieldModel: total_yieldModel,
                total_irrigation: total_irrigation
            };
        }

    }
})(angular);
