(function (angular) {
    "use strict";
    angular.module("PotatoApp")
        .controller("TempEmergenceController", ["$scope", "plot_slug", "emergence_type", "min_date", "TempEmergenceFactory", "$khMessage", "$filter", "$mdDialog", "DateTimeHelperFactory", TempEmergenceController]);

    function TempEmergenceController($scope, plot_slug, emergence_type, min_date, TempEmergenceFactory, $khMessage, $filter, $mdDialog, DateTimeHelperFactory) {
        $scope.min_date = min_date;
        $scope.submitEmergence = submitEmergence;
        $scope.emergence_date_form = temporaryEmergenceForm();
        $scope.emergenceDetail = {};

        function temporaryEmergenceForm() {
            return [
                {
                    className: "flex-100",
                    key: "emergenceDate",
                    type: "datepicker",
                    templateOptions: {
                        label: "Emergence date",
                        required: true,
                        minDate: new Date(min_date)
                    }
                }
            ];
        }

        function submitEmergence(formData) {
            var utcTime = DateTimeHelperFactory.convertDatePickerToDateString(formData.emergenceDate);
            var submissionObject = {
                fieldplotcrop: plot_slug,
                emergence_date: utcTime,
                emergence_type: emergence_type
            };
            TempEmergenceFactory.save(submissionObject, function (success) {
                $khMessage.show($filter("translate")("created_successfully"), 5000);
                $mdDialog.hide(success);
            }, function (error) {
            });
        }

    }

})(angular);
