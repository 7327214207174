(function (angular) {
    'use strict';
    angular.module('PotatoApp')
        .controller('PotatoCropDetailController', [
            '$scope',
            '$rootScope',
            '$window',
            '$mdDialog',
            '$khMessage',
            '$filter',
            'CanopyCoverDetailFactory',
            'CanopyCoverImageFactory',
            'YieldModelOutputFactory',
            'parentControllerData',
            PotatoCropDetailController
        ]);

    function PotatoCropDetailController($scope,
        $rootScope,
        $window,
        $mdDialog,
        $khMessage,
        $filter,
        CanopyCoverDetailFactory,
        CanopyCoverImageFactory,
        YieldModelOutputFactory,
        parentControllerData) {

        $scope.tenant_id = tenant_id;
        loadCropDetailAPIData();
        function loadCropDetailAPIData() {
            loadMileStoneSummaryData();
            loadPlotCanopyCoverAPIData();
            loadCanopyCoverImages();
            loadYieldModelOutputAPIData();
        }

        function loadMileStoneSummaryData() {
            $scope.plotData = parentControllerData.plotData;
        }

        function loadPlotCanopyCoverAPIData() {
            CanopyCoverDetailFactory.get(getPlotCanopyCoverAPIQueryParam(), function (canopyCoverAPIData) {
                if (canopyCoverAPIData.hasOwnProperty('canopy_cover_development_chart_data') &&
                    canopyCoverAPIData.canopy_cover_development_chart_data.hasOwnProperty('chart_configuration')) {
                    $scope.plotCanopyCoverAPIData = canopyCoverAPIData;
                } else {
                    $scope.plotCanopyCoverAPIData = null;
                }
            }, function (error) {
                $scope.plotCanopyCoverAPIData = null;
            });

        }

        function getPlotCanopyCoverAPIQueryParam() {
            return {'field_plot_crop_slug': parentControllerData.plotData.plot_slug};
        }

        function getPlotCanopyCoverImagesAPIQueryParam() {
            return {'field_plot_crop_slug': parentControllerData.plotData.plot_slug};
        }

        function loadCanopyCoverImages() {
            CanopyCoverImageFactory.query(getPlotCanopyCoverImagesAPIQueryParam(), function (data) {
                $scope.canopyCoverData = data;
                $scope.canopyCoverImages = [];
                angular.forEach(data, function (image_entry) {
                    angular.forEach(image_entry.images, function (image) {
                        $scope.canopyCoverImages.push({
                            input_image_path: extractFilePath(image.input_image),
                            processed_image_path: extractFilePath(image.processed_image),
                            recorded_on: moment(image_entry.recorded_on).format($rootScope.userSettingDateFormat),
                            progress_value: image.progress_value,
                            slug: image_entry.slug,
                            imageIndex: image_entry.images.indexOf(image),
                            processed_status: image.processed_status
                        });
                    });
                });
            });
        }

        function deleteCanopyCoverImage(image) {
            var confirm = $mdDialog.confirm()
                .multiple(true)
                .title($filter('translate')('are_you_sure_to_delete_this_canopy_cover_image'))
                .ariaLabel($filter('translate')('delete_canopy_cover_image'))
                .ok($filter('translate')('yes'))
                .cancel($filter('translate')('no'));
            $mdDialog.show(confirm).then(function () {
                var imageEntry = _.find($scope.canopyCoverData, function (canopyCover) {
                    return image.slug === canopyCover.slug;
                });
                imageEntry.images.splice(image.imageIndex, 1);
                CanopyCoverImageFactory.update({crop_progress_slug: imageEntry.slug}, {images: imageEntry.images}, function () {
                    $khMessage.show('Deleted canopy cover image successfully.', 3000);
                    $scope.canopyCoverImages.splice($scope.canopyCoverImages.indexOf(image), 1);
                });
            }, function () {
            });
        }
        $scope.deleteCanopyCoverImage = deleteCanopyCoverImage;

        function extractFilePath(image) {
            if (image && image.hasOwnProperty('file_path')) {
                return image.file_path;
            }
        }

        function loadYieldModelOutputAPIData() {
            YieldModelOutputFactory.getYieldChartApiData(parentControllerData.plotData.plot_slug, $scope);
            YieldModelOutputFactory.getYieldChartApiData(parentControllerData.plotData.plot_slug, $scope);
        }

    }

})(angular);