(function (angular) {
    "use strict";
    angular.module('PotatoApp')
        .factory('YieldModelOutputFactory', ['$filter', 'YieldModelOutputBuilderFactory', YieldModelOutputFactory]);

    function YieldModelOutputFactory($filter, YieldModelOutputBuilderFactory) {
        var helperFunctions = {

            getYieldChartApiData: function (fpc_slug, scope) {
                return YieldModelOutputBuilderFactory.get({fpc_slug: fpc_slug}, function (success) {
                    scope.YieldModelOutputAPIData = helperFunctions.getYieldChartObject(success);
                    scope.latest_model_run_date = success['latest_model_run_date'];
                    scope.no_of_model_runs = success['no_of_model_runs'];
                }, function (error) {

                });
            },

            plotLinesFetcher: function (apiData) {
                var plotLines = [
                    {
                        color: '#43A047',
                        width: 2,
                        value: apiData.forecast_date,
                        label: {
                            text: 'Forecast date'
                        }
                    }
                ];
                return plotLines;
            },

            seriesFetcher: function (apiData) {
                var dataSeries = [
                    {
                        name: 'Observed ground cover (range)',
                        color: 'rgba(221, 44, 0, 0.5)',
                        type: 'arearange',
                        yAxis: 0,
                        data: apiData.canopy_cover_range
                    },
                    {
                        name: 'Average ground cover',
                        color: '#FFBF41',
                        type:"spline",
                        yAxis: 0,
                        data: apiData.combined_canopy,
                        zIndex: 1,
                        marker: {
                            fillColor: 'white',
                            lineWidth: 2,
                            lineColor: '#FFBF41'
                        }
                    },
                    {
                        name: 'Forecast yield',
                        color: '#448AFF',
                        type:"spline",
                        yAxis: 1,
                        data: apiData.total_yields,
                        zIndex: 1,
                        marker: {
                            fillColor: 'white',
                            lineWidth: 2,
                            lineColor: Highcharts.getOptions().colors[0]
                        }
                    },
                    {
                        name: 'Forecast yield range',
                        color: '#448AFF',
                        type: 'arearange',
                        lineWidth: 0,
                        fillOpacity: 0.3,
                        zIndex: 0,
                        linkedTo: ':previous',
                        yAxis: 1,
                        data: apiData.yield_forecast_ranges
                    },
                    {
                        name: 'Yield digs',
                        color: '#830EFF',
                        type:"spline",
                        yAxis: 1,
                        lineWidth: 0,
                        data: apiData.yield_digs,
                        zIndex: 2,
                        marker: {
                            enabled: true,
                            radius: 5,
                            symbol: 'circle',
                            fillColor: 'white',
                            lineColor: '#830EFF',
                            lineWidth: 2
                        }
                    }
                ];
                return dataSeries
            },

            getYieldChartObject: function (apiData) {
                return {
                    "forecast_yield_development_chart_data": {
                        "chart_configuration": {
                            title: {
                                text: 'Forecast Yield Development'
                            },
                            chart: {
                                alignTicks: false
                            },
                            credits: {
                                enabled: false
                            },
                            xAxis: {
                                type: 'datetime',
                                labels: {
                                    format: '{value: %e %b}'
                                },
                                plotLines: helperFunctions.plotLinesFetcher(apiData)
                            },
                            yAxis: [
                                {
                                    min: 0,
                                    max: 100,
                                    title: {
                                        text: 'Forecast ground cover',
                                        style: {
                                            color: '#DD2C00'
                                        }
                                    },
                                    labels: {
                                        formatter: function () {
                                            return $filter('number')(this.value, 0) + '%';
                                        },
                                        style: {
                                            color: '#DD2C00'
                                        }
                                    }
                                }, {
                                    min: 0,
                                    max: 100,
                                    title: {
                                        text: 'Forecast yield',
                                        style: {
                                            color: '#448AFF'
                                        }
                                    },
                                    labels: {
                                        formatter: function () {
                                            return $filter('number')(this.value, 0) + ' t/ha';
                                        },
                                        style: {
                                            color: '#448AFF'
                                        }
                                    },
                                    opposite: true

                                }],
                            plotOptions: {
                                series: {
                                    marker: {
                                        enabled: false
                                    }
                                }
                            },
                            tooltip: {
                                useHTML: true,
                                crosshairs: true,
                                shared: true,
                                dateTimeLabelFormats: {
                                    week: "%A, %b %e, %Y"
                                },
                                formatter: function () {
                                    var points = this.points;
                                    var pointsLength = points.length;
                                    var point_date = new Date(points[0].key);
                                    var tooltipMarkup = pointsLength ? '<span style="font-size: 10px">' + point_date.toDateString() + '</span><br/>' : '';

                                    for (var index = 0; index < pointsLength; index += 1) {
                                        if (points[index].series.name == "Average ground cover") {
                                            tooltipMarkup += '<span style="color:' + points[index].series.color + '">\u25CF</span> ' + points[index].series.name + ': <b>' + $filter('number')(points[index].y, 0) + ' %</b><br/>';
                                        }
                                        if (points[index].series.name == "Yield digs") {
                                            tooltipMarkup += '<span style="color:' + points[index].series.color + '">\u25CF</span> Yield from dig: <b>' + $filter('number')(points[index].y, 1) + ' t/ha</b><br/>';
                                        }
                                        if (points[index].series.name == "Forecast yield") {
                                            tooltipMarkup += '<span style="color:' + points[index].series.color + '">\u25CF</span> ' + points[index].series.name + ': <b>' + $filter('number')(points[index].y, 1) + ' t/ha</b><br/>';
                                        }
                                    }

                                    return tooltipMarkup;
                                }
                            },

                            legend: {
                                enabled: true,
                                align: 'center',
                                verticalAlign: 'bottom',
                                backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColorSolid) || 'white',
                                borderColor: '#CCC',
                                borderWidth: 0,
                                shadow: false,
                                y: -4
                            },
                            series: helperFunctions.seriesFetcher(apiData)
                        }
                    },
                    "tuber_size_distribution_chart_data": {
                        "chart_configuration": {
                            chart: {
                                type: 'area'
                            },
                            title: {
                                text: 'Yield forecast: Tuber size distribution by date'
                            },
                            xAxis: {
                                type: "datetime",
                                labels: {
                                    format: '{value: %e %b}'
                                }
                            },
                            yAxis: {
                                min: 0,
                                title: {
                                    text: '',
                                    "enabled": false
                                },
                                labels: {
                                    formatter: function () {
                                        return this.value + ' t/ha';
                                    }
                                }
                            },
                            tooltip: {
                                useHTML: true,
                                shared: true,
                                formatter: function () {
                                    var s = '<b>' + moment(new Date(this.x)).format("ddd MMM DD YYYY") + '</b><br/>';

                                    s += '<table>';
                                    s += '<tbody>';
                                    $.each(this.points, function () {
                                        s += '<tr style="background-color:' + hexToRGBA(this.series.color, 0.25) + ';">' +
                                            '<td>' + this.series.name + '</td>' +
                                            '<td>' + this.point.y.toFixed(1) + 't/ha</td>' +
                                            '<td>' + ' ' + this.point.percentage.toFixed(0) + '%</td>' +
                                            '</tr>';
                                    });
                                    s += '</tbody>';
                                    s += '</table>';

                                    return s;
                                }
                            },
                            plotOptions: {
                                area: {
                                    stacking: 'normal',
                                    lineColor: '#666666',
                                    lineWidth: 1,
                                    marker: {
                                        enabled: false
                                    }
                                }
                            },
                            series: apiData.yield_distribution_profile.map(function (partition) {
                                return {
                                    name: partition.partition_name,
                                    data: partition.data
                                }
                            })
                        }
                    }
                }
            }

        };

        function hexToRGBA(hex, alpha) {
            var r = parseInt(hex.slice(1, 3), 16),
                g = parseInt(hex.slice(3, 5), 16),
                b = parseInt(hex.slice(5, 7), 16);
            if (alpha) {
                return "rgba(" + r + "," + g + "," + b + "," + alpha + ")";
            } else {
                return "rgb(" + r + "," + g + "," + b + ")";
            }
        }

        return helperFunctions;
    }

})(angular);



