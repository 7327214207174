(function (angular) {
    "use strict";
    angular.module("PotatoApp")
        .factory("TempEmergenceFactory", TempEmergenceFactory);

    TempEmergenceFactory.$inject = ["$resource"];

    function TempEmergenceFactory($resource) {
        return $resource("api/v1.0/potato/emergence-date/:emergenceRecordId/", {emergenceRecordId: "@emergenceRecordId"},
            {
                save: {
                    method: "POST"
                },
                update: {
                    method: "PUT"
                }
            });
    }

})(angular);