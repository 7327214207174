(function (angular) {
    "use strict";
    angular.module('PotatoApp')
        .factory('BurndownModellingForm', BurndownModellingFormFactory);

    BurndownModellingFormFactory.$inject = ['$khMessage', 'FieldPlotCropFactory', 'PotatoYieldModelOutput'];

    function BurndownModellingFormFactory($khMessage, FieldPlotCropFactory, PotatoYieldModelOutput) {
        var formHelpers = {

            generateForm: function (rootScope, scope) {
                FieldPlotCropFactory.query(
                    {
                        "growing_season": getGrowingSeason(rootScope),
                        "all": true
                    },
                    function (result) {
                        filterFieldPlotCropsByCrop(result, "Potato", updateFormFields, scope);
                    }, function (error) {
                        $khMessage.show("Failed to load list of crops. Please refresh the page and try again.", 5000)
                    }
                );

                function filterFieldPlotCropsByCrop(input_data, crop, callback, target) {
                    var data = input_data[0];
                    var features = data.features;
                    var potato_crops = [];
                    angular.forEach(features, function (element, idx, obj) {
                        if (element.properties.plot_crop === crop) {
                            var plot_name = element.properties.farm_name + ' - ' + element.properties.field_name + ' - ' + element.properties.plot_name + ' - ' + element.properties.plot_variety;
                            potato_crops.push({
                                value: element.properties.plot_slug,
                                name: plot_name
                            })
                        }
                    });
                    callback(potato_crops, target);
                }

                function getGrowingSeason(rootScope) {
                    if (rootScope.selected_growing_season) {
                        return rootScope.selected_growing_season.value
                    }
                }

                function updateFormFields(potato_crops, target) {
                    target.burndownModellingFields = formHelpers.fetch(potato_crops);
                }
            },

            fetch: function (fieldPlotCrops) {
                return [
                    {
                        className: 'layout-row',
                        fieldGroup: [
                            {
                                key: "field_plot_crop",
                                className: "flex-50",
                                type: "searchable_select",
                                templateOptions: {
                                    valueProp: 'value',
                                    labelProp: 'name',
                                    label: "Crop",
                                    options: fieldPlotCrops,
                                    required: true
                                }
                            },
                            {
                                key: "model_run",
                                className: "flex-50",
                                type: "select",
                                templateOptions: {
                                    label: "Model Run",
                                    valueProp: 'value',
                                    labelProp: 'name',
                                    options: [],
                                    required: true
                                },
                                expressionProperties: {
                                    "templateOptions.disabled": "!model.field_plot_crop"
                                },
                                controller: function ($scope, PotatoYieldModelOutput) {
                                    $scope.$watch("$parent.model.field_plot_crop", function handleChange(newVal, oldVal) {
                                        if (typeof newVal !== "undefined") {
                                            PotatoYieldModelOutput.query(
                                                {
                                                    fieldplotcrop: newVal,
                                                    representation: "drop-down"
                                                },
                                                function (success) {
                                                    if (!success.length > 0) {
                                                        $khMessage.show("There is no model output available for the " +
                                                            "selected Crop. Please select a different Crop.", 5000)
                                                    }
                                                    $scope.to.options = success;
                                                },
                                                function (error) {
                                                    $khMessage.show("It was not possible to retrieve model output." +
                                                        "Please refresh and try again.", 5000)
                                                }
                                            );
                                        }
                                    });
                                }
                            }
                        ]
                    }
                ]
            },

            potatoCrops: function (scope, growing_season) {

            },

            retrieveModelOutput: function (model_run_slug, successCallback, errorCallback) {
                PotatoYieldModelOutput.get(
                    {model_run_slug: model_run_slug},
                    function (success) {
                        successCallback(success)
                    }, function (error) {
                        errorCallback(error)
                    }
                )
            }

        };
        return formHelpers;
    }
})(angular);
