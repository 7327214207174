(function () {

    'use strict';

    angular.module('PotatoApp').run(['$rootScope', '$http', 'KhutilsCropListFactory', 'UserSettingsFactory',
        'DateTimeHelperFactory', "formlyConfig", potatoRun]);

    function potatoRun($rootScope, $http, KhutilsCropListFactory, UserSettingsFactory, DateTimeHelperFactory, formlyConfig) {
        $http.defaults.xsrfCookieName = "csrftoken";
        $http.defaults.xsrfHeaderName = "X-CSRFToken";

        $rootScope.in_app_add_button = '/static/assets/khapps/crops/potato/secondary_navbar/potato_navbar_add_button.html';
        $rootScope.in_app_links_template = '/static/assets/khapps/crops/potato/secondary_navbar/potato_navbar_links.html';
        $rootScope.view_as_template = "/static/assets/khapps/crops/potato/secondary_navbar/potato_navbar_views.html";
        $rootScope.select_filters_template = "/static/assets/khapps/crops/potato/secondary_navbar/potato_navbar_filters.html";

        $rootScope.potato_base_url = "/dashboard/crops/potato/#";
        $rootScope.dashboard_url = "/dashboard/";

        (function getCrop() {
            KhutilsCropListFactory.query(function (result) {
                $rootScope.crop = result.filter(function (crop) {
                    return crop.crop_name == "Potato"
                })[0].crop_id;
            });
        })();

        UserSettingsFactory.get(function (result) {
            if (result.DEFAULT_USER_READABLE_DATE_FORMAT) {
                $rootScope.userSettingDateFormat = DateTimeHelperFactory.convertDateTimeFormatFromPythonToMoment(
                    result.DEFAULT_USER_READABLE_DATE_FORMAT);
            } else {
                $rootScope.userSettingDateFormat = 'DD MMM, YYYY';
            }
            if (result.DEFAULT_USER_READABLE_TIME_FORMAT) {
                $rootScope.userSettingTimeFormat = DateTimeHelperFactory.convertDateTimeFormatFromPythonToMoment(
                    result.DEFAULT_USER_READABLE_TIME_FORMAT);
            } else {
                $rootScope.userSettingTimeFormat = 'HH:mm';
            }
        });

        //KH-datepicker
        formlyConfig.setType({
            name: 'khDatePicker',
            templateUrl: "/static/assets/khapps2/base/khformelements/partials/kh_datepicker.html"
        });

        // Repeat Section
        formlyConfig.setType({
            name: 'replicateRepeatSection',
            templateUrl: 'replicateRow.html',
            controller: function ($scope) {
                var unique = 1;
                $scope.formOptions = {formState: $scope.formState};
                $scope.addNew = addNewPartition;
                $scope.isCurrent = checkIfCurrenPartition;
                $scope.addFinal = addFinalPartition;
                $scope.isFinal = checkIfFinalPartition;
                $scope.copyFields = copyFields;
                $scope.removePartition = removePartition;

                function copyFields(fields) {
                    fields = angular.copy(fields);
                    addRandomIds(fields);
                    return fields;
                }

                function addNewPartition(index) {
                    $scope.model[$scope.options.key] = $scope.model[$scope.options.key] || [];
                    var repeatsection = $scope.model[$scope.options.key];
                    // Used to duplicate the values from the previous section into the new section:
                    var lastSection = repeatsection[repeatsection.length - 1];
                    var newsection = {};
                    if (lastSection) {
                        newsection = angular.copy(lastSection);
                        newsection.partition_count = 0;
                        newsection.partition_weight = 0;
                    }
                    if (lastSection && lastSection.hasOwnProperty("partition_upper_bound")) {
                        newsection.partition_lower_bound = lastSection.partition_upper_bound;
                        newsection.partition_upper_bound = lastSection.partition_upper_bound + 10;
                    }
                    lastSection.is_current_partition = false;
                    repeatsection.push(newsection);
                }

                function checkIfCurrenPartition(idx, gradingProfile) {
                    if (gradingProfile.length - 1 === idx) {
                        return true
                    } else {
                        return false
                    }
                }

                function addFinalPartition(index) {
                    $scope.model[$scope.options.key] = $scope.model[$scope.options.key] || [];
                    var repeatsection = $scope.model[$scope.options.key];
                    // Used to duplicate the values from the previous section into the new section:
                    var lastSection = repeatsection[repeatsection.length - 1];
                    var newsection = {};
                    if (lastSection) {
                        newsection = angular.copy(lastSection);
                        newsection.partition_count = 0;
                        newsection.partition_weight = 0;
                    }
                    if (lastSection && lastSection.hasOwnProperty("partition_upper_bound")) {
                        newsection.partition_lower_bound = lastSection.partition_upper_bound;
                        newsection.partition_upper_bound = null;
                        newsection.is_final_partition = true;
                    }
                    lastSection.is_current_partition = false;
                    repeatsection.push(newsection);
                }

                function checkIfFinalPartition(gradingProfile) {
                    var latestElement = gradingProfile[gradingProfile.length - 1];
                    if (latestElement.is_final_partition) {
                        return true
                    } else {
                        return false
                    }
                }

                function removePartition (replicate, partitionIndex) {
                    replicate.splice(partitionIndex, 1);
                    updateCurrentPartition(replicate);
                }

                function updateCurrentPartition (replicate) {
                    replicate[replicate.length - 1].is_current_partition = true;
                }

                function addRandomIds(fields) {
                    unique++;
                    angular.forEach(fields, function (field, index) {
                        if (field.fieldGroup) {
                            addRandomIds(field.fieldGroup);
                            return; // fieldGroups don't need an ID
                        }

                        if (field.templateOptions && field.templateOptions.fields) {
                            addRandomIds(field.templateOptions.fields);
                        }

                        field.id = field.id || (field.key + '_' + index + '_' + unique + getRandomInt(0, 9999));
                    });
                }

                function getRandomInt(min, max) {
                    return Math.floor(Math.random() * (max - min)) + min;
                }
            }
        });

        formlyConfig.setType({
            name: 'templateRepeatSection',
            templateUrl: 'templateRow.html',
            controller: function ($scope) {
                var unique = 1;
                $scope.formOptions = {formState: $scope.formState};
                $scope.copyFields = copyFields;
                $scope.addNew = addNewPartition;
                $scope.isCurrent = checkIfCurrenPartition;
                $scope.resetCurrentPartition = resetCurrentPartition;
                $scope.addFinal = addFinalPartition;
                $scope.isFinal = checkIfFinalPartition;
                function copyFields(fields) {
                    fields = angular.copy(fields);
                    addRandomIds(fields);
                    return fields;
                }

                function checkIfCurrenPartition(idx, gradingProfile) {
                    if (gradingProfile.length - 1 === idx) {
                        return true
                    } else {
                        return false
                    }
                }

                function addNewPartition() {
                    $scope.model[$scope.options.key] = $scope.model[$scope.options.key] || [];
                    var repeatsection = $scope.model[$scope.options.key];
                    var lastSection = repeatsection[repeatsection.length - 1];
                    var newsection = {};

                    angular.forEach(repeatsection, function (elem, idx, obj) {
                        elem.is_current_partition = false;
                    });

                    if (lastSection) {
                        newsection = angular.copy(lastSection);
                    }
                    if (lastSection && lastSection.hasOwnProperty("partition_upper_bound")) {
                        newsection.partition_lower_bound = lastSection.partition_upper_bound;
                        newsection.partition_upper_bound = lastSection.partition_upper_bound + 10;
                        newsection.is_current_partition = true;
                    } else {
                        newsection.is_current_partition = true;
                    }
                    repeatsection.push(newsection);
                }

                function addFinalPartition(index) {
                    $scope.model[$scope.options.key] = $scope.model[$scope.options.key] || [];
                    var repeatsection = $scope.model[$scope.options.key];
                    // Used to duplicate the values from the previous section into the new section:
                    var lastSection = repeatsection[repeatsection.length - 1];
                    var newsection = {};
                    angular.forEach(repeatsection, function (elem, idx, obj) {
                        elem.is_current_partition = false;
                    });
                    if (lastSection) {
                        newsection = angular.copy(lastSection);
                    }
                    if (lastSection && lastSection.hasOwnProperty("partition_upper_bound")) {
                        newsection.partition_lower_bound = lastSection.partition_upper_bound;
                        newsection.partition_upper_bound = null;
                        newsection.is_final_partition = true;
                    }
                    repeatsection.push(newsection);
                }

                function resetCurrentPartition() {
                    var repeatsection = $scope.model[$scope.options.key];
                    var lastIdx = repeatsection.length - 1;
                    angular.forEach(repeatsection, function (elem, idx, obj) {
                        if (idx === lastIdx) {
                            elem.is_current_partition = true;
                        }
                    });
                }

                function checkIfFinalPartition(gradingProfile) {
                    if (gradingProfile) {
                        var latestElement = gradingProfile[gradingProfile.length - 1];
                        if (latestElement.is_final_partition) {
                            return true
                        } else {
                            return false
                        }
                    }
                    return false
                }

                function addRandomIds(fields) {
                    unique++;
                    angular.forEach(fields, function (field, index) {
                        if (field.fieldGroup) {
                            addRandomIds(field.fieldGroup);
                            return; // fieldGroups don't need an ID
                        }

                        if (field.templateOptions && field.templateOptions.fields) {
                            addRandomIds(field.templateOptions.fields);
                        }

                        field.id = field.id || (field.key + '_' + index + '_' + unique + getRandomInt(0, 9999));
                    });
                }

                function getRandomInt(min, max) {
                    return Math.floor(Math.random() * (max - min)) + min;
                }
            }
        });

    }

})();
