(function (angular) {
    "use strict";
    angular.module('PotatoApp')
        .factory('BurndownModellingRevenueCharts', PotatoBurndownModellingRevenueChartsFactory);

    PotatoBurndownModellingRevenueChartsFactory.$inject = ['$filter'];

    function PotatoBurndownModellingRevenueChartsFactory($filter) {

        var helpers = {

            revenueStatus: function (priceAssumptions) {
                if (priceAssumptions) {
                    var prices = Object.values(priceAssumptions);
                    var nonNull = prices.filter(function (priceElement) {
                        return priceElement
                    });
                    if (prices.length > 0 && nonNull.length > 0) {
                        return true
                    }
                }
                return false
            },

            revenueDistributions: function (chartData, priceAssumptions) {
                var scenarioOneRevenueDistribution = helpers.revenueDistributionForScenario(chartData.scenarioOnePartitions, priceAssumptions);
                var scenarioTwoRevenueDistribution = helpers.revenueDistributionForScenario(chartData.scenarioTwoPartitions, priceAssumptions);
                return [scenarioOneRevenueDistribution, scenarioTwoRevenueDistribution]
            },

            revenueDistributionForScenario: function (scenarioYieldDistribution, priceAssumptions) {
                var revenueDistribution = [];
                angular.forEach(scenarioYieldDistribution, function (element, idx, obj) {
                    if (priceAssumptions[idx]) {
                        revenueDistribution.push(priceAssumptions[idx] * element)
                    }
                    else {
                        revenueDistribution.push(null)
                    }
                });
                return revenueDistribution;
            },

            totalRevenues: function (scenarioRevenueDistributions) {
                var totalRevenues = [];
                angular.forEach(scenarioRevenueDistributions, function (scenarioRevenues, scenarioIdx, scenarioArray) {
                    totalRevenues.push(helpers.totalRevenueForScenario(scenarioRevenues));
                });
                return totalRevenues
            },

            totalRevenueForScenario: function (scenarioRevenues) {
                var scenarioTotal = 0;
                angular.forEach(scenarioRevenues, function (partitionRevenue, partitionIdx, scenarioRev) {
                    if (partitionRevenue) {
                        scenarioTotal += partitionRevenue;
                    }
                });
                return scenarioTotal;
            },

            revenueDistributionChart: function (partitionLabels, revenueDistributions) {
                return {
                    chart: {
                        type: 'column'
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: 'Revenue per hectare by tuber size (£/ha)'
                    },
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                        layout: 'horizontal'
                    },

                    xAxis: {
                        title: {
                            enabled: false
                        },
                        labels: {
                            enable: false,
                            x: -10
                        },
                        categories: partitionLabels
                    },

                    yAxis: {
                        allowDecimals: false,
                        title: {
                            enabled: false,
                            text: 'Tuber yield distribution (t/ha)'
                        }
                    },
                    tooltip: {
                        headerFormat: '',
                        pointFormat: '{series.name}: <b>£{point.y:.0f} / ha</b><br/>'
                    },
                    series: [
                        {
                            name: "Scenario 1",
                            color: '#522bb1',
                            data: revenueDistributions[0]
                        }, {
                            name: "Scenario 2",
                            color: '#1e87a0',
                            data: revenueDistributions[1]
                        }
                    ]
                }
            },

            revenueTotalsChart: function (revenueTotals) {
                return {
                    chart: {
                        type: 'bar'
                    },
                    title: {
                        text: 'Revenue per hectare by tuber size (£/ha) '
                    },
                    xAxis: {
                        labels: {
                            enabled: false
                        },
                        title: {
                            text: null
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: '',
                            align: 'high'
                        },
                        labels: {
                            overflow: 'justify'
                        }
                    },
                    tooltip: {
                        headerFormat: '',
                        pointFormat: '{series.name}: <b>£{point.y:.1f} / ha</b><br/>'
                    },
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                enabled: true,
                                format: "£{y:.1f}"
                            }
                        }
                    },
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom',
                        floating: false,
                        borderWidth: 1,
                        backgroundColor: ((Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'),
                        shadow: true
                    },
                    credits: {
                        enabled: false
                    },
                    series: [
                        {
                            name: "Scenario 1",
                            color: '#522bb1',
                            data: [revenueTotals[0]]
                        }, {
                            name: "Scenario 2",
                            color: '#1e87a0',
                            data: [revenueTotals[1]]
                        }
                    ]
                }
            }

        };

        return helpers;

    }

})
(angular);
