(function (angular) {
    "use strict";
    angular.module('PotatoApp')
        .factory('YieldModelOutputBuilderFactory', YieldModelOutputBuilderFactory);

    function YieldModelOutputBuilderFactory($resource) {
    var url = "/api/v1.0/potato/yield-prediction-detail/:fpc_slug/";
      return $resource(url, {fpc_slug: '@fpc_slug'}, {
        get: {
            method: "GET"
        }
    });
    }
})(angular);
