(function (angular) {
    "use strict";
    angular.module("PotatoApp")
        .controller("PotatoBurndownController", ["$scope", "$rootScope", "$mdDialog", "$khMessage", "$filter",
            "plot_slug", "plot_area", "emergence_date_string", "season",
            "PotatoOperations", "PotatoBurndownBuilder", "GenericPesticideProduct", PotatoBurndownController]);

    function PotatoBurndownController($scope, $rootScope, $mdDialog, $khMessage, $filter,
                                      plot_slug, plot_area, emergence_date_string, season,
                                      PotatoOperations, PotatoBurndownBuilder, GenericPesticideProduct) {
        var vm = this;
        $rootScope.view_only_irrigation = false;
        $scope.plot_slug = plot_slug;
        $scope.plot_area = plot_area;
        $scope.emergence_date_string = emergence_date_string;
        $scope.season = season;
        if (angular.isUndefined($scope.burndown_date_form)) {
            GenericPesticideProduct.query({}, function (success) {
                $scope.burndown_date_form = potatoBurndownForm(success);
            }, function (error) {
                console.log(error);
            });
        }
        $scope.burndownDetail = {};
        $scope.submitBurndown = submitBurndown;

        function potatoBurndownForm(burndown_products) {
            return [
                {
                    className: "flex-100",
                    key: "burndownDate",
                    type: "datepicker",
                    templateOptions: {
                        label: "Burndown date",
                        required: true,
                        minDate: new Date(emergence_date_string)
                    }
                },
                {
                    className: "flex-100",
                    key: "product",
                    type: "searchable_select",
                    templateOptions: {
                        label: "Product",
                        required: false,
                        options: burndown_products
                    }
                }
            ];
        }

        function submitBurndown(slug, formData, plot_area, season) {
            var burndownSubmission = PotatoBurndownBuilder.create(slug, formData, plot_area, season);
            PotatoOperations.save({method: "multiple"}, burndownSubmission, function (success) {
                $khMessage.show($filter("translate")("created_successfully"), 5000);
                success["plot_slug"] = plot_slug;
                success["burndown_slug"] = success["response"][0]["operation_slug"];
                success["burndown_date"] = formData.burndownDate;
                success["burndown_date_string"] = moment(formData.burndownDate).format("DD MMM YYYY");
                success["burndown_actual_status"] = true;
                success["burndown_status_description"] = "Record";
                $mdDialog.hide(success);
            }, function (error) {
                $khMessage.show("Failed to record burndown details", 5000);
                var errorMessage = error["data"]["fieldplotcrops"][0];
                $khMessage.show(errorMessage, 5000);
            });
        }

    }

})(angular);
