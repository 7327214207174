(function (angular) {
    "use strict";
    angular.module("PotatoApp")
        .service("YieldForecastSummary", ["$resource", YieldForecastSummaryFactory]);

    function YieldForecastSummaryFactory($resource) {
        var url = "/api/potato/forecast-summary/";
        var queryParamDict = {
            farm: "@farm"
        };

        return $resource(url, {},
            {
                query: {
                    params: queryParamDict,
                    url: url,
                    isArray: false
                },
                get: {
                    params: {field_plot_crop_slug: "@field_plot_crop_slug"},
                    // url: '/api/v1.0/external/niabcuf/ground-cover/' + ':field_plot_crop_slug/',
                    url: url + ":field_plot_crop_slug/",
                    method: "GET"
                }
            }
        );
    }

})(angular);