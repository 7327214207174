(function(angular){
    "use strict";
    angular.module('PotatoApp')
        .factory('PotatoAdminDataLoader', ["PotatoAdminDashboard", "PotatoAdminSummary", "PotatoAdminEmergenceSummary", "PotatoAdminCanopySummary", "PotatoAdminYieldForecastSummary", "NgTableParams", PotatoAdminDataLoaderFactory]);

    function PotatoAdminDataLoaderFactory(PotatoAdminDashboard, PotatoAdminSummary, PotatoAdminEmergenceSummary, PotatoAdminCanopySummary, PotatoAdminYieldForecastSummary, NgTableParams){
        var summaryData;
        var growing_season;
        var dataLoaders = {

            loadAdminSummaryData: function(scope, selected_growing_season, successCallback) {
                if (summaryData && (growing_season === selected_growing_season)) {
                    successCallback(scope, summaryData);
                } else {
                    growing_season = selected_growing_season;
                    PotatoAdminSummary.query({growing_season: selected_growing_season}, function (success) {
                        successCallback(scope, summaryData=success);
                    }, function (error) {
                    //     errorCallback()
                    })
                }
            },

            loadAdminMilestoneSummaryData: function(scope, teamSlug) {
                var queryParams = dataLoaders.getGenericQueryParams(scope, teamSlug);
                scope.mileStoneSummaryApiData = null;
                PotatoAdminDashboard.get(queryParams, function(success) {
                    scope.mileStoneSummaryApiData = success;
                    scope.showEmptyState = !success.length;
                    scope.mileStoneSummaryTableData = new NgTableParams({count: 20}, {
                        counts: [20, 50, 100, 200],
                        dataset: success
                    });
                }, function(error) {
                    scope.mileStoneSummaryApiData = null;
                })
            },

            loadAdminEmergenceSummaryData: function(scope, teamSlug) {
                var queryParams = dataLoaders.getGenericQueryParams(scope, teamSlug);
                scope.emergenceAPIData = null;
                PotatoAdminEmergenceSummary.get(queryParams, function(success) {
                    scope.emergenceAPIData = success;
                }, function(error) {
                    scope.emergenceAPIData = null;
                });
            },

            loadAdminCanopySummaryData: function(scope, teamSlug) {
                var queryParams = dataLoaders.getGenericQueryParams(scope, teamSlug);
                scope.canopyCoverAPIData = null;
                PotatoAdminCanopySummary.get(queryParams, function(success) {
                    scope.canopyCoverAPIData = success;
                }, function(error) {
                    scope.canopyCoverAPIData = null;
                });
            },

            loadAdminYieldForecastSummaryData: function(scope, teamSlug) {
                var queryParams = dataLoaders.getGenericQueryParams(scope, teamSlug);
                scope.yieldForecastAPIData = null;
                PotatoAdminYieldForecastSummary.get(queryParams, function(success) {
                    scope.yieldForecastAPIData = success;
                }, function(error) {
                    scope.yieldForecastAPIData = null;
                });
            },

            getGenericQueryParams: function(scope, teamSlug) {
                var query_params = {teamSlug: teamSlug};
                if (scope.selected_growing_season) {
                    query_params["growing_season"] = scope.selected_growing_season.value;
                }
                return query_params;
            }
        };
        return dataLoaders;
    }

})(angular);
