(function (angular) {
    "use strict";
    angular.module('PotatoApp')
        .factory('PotatoBurndownModellingYieldCharts', PotatoBurndownModellingYieldChartsFactory);

    PotatoBurndownModellingYieldChartsFactory.$inject = ['$filter'];

    function PotatoBurndownModellingYieldChartsFactory($filter) {

        var helpers = {

            getYieldModelChartConfig: function (totalYieldProfile, scenarioOneDate, scenarioTwoDate) {
                var scenarioOneData = [scenarioOneDate, extractYieldForDate(totalYieldProfile, scenarioOneDate)];
                var scenarioTwoData = [scenarioTwoDate, extractYieldForDate(totalYieldProfile, scenarioTwoDate)];

                function extractYieldForDate(totalYields, targetDate) {
                    var yieldForDate = null;
                    angular.forEach(totalYields, function (element, idx, obj) {
                        if (element[0] === targetDate) {
                            yieldForDate = element[1];
                        }
                    });
                    return yieldForDate;
                }

                return {
                    title: {
                        text: 'Total Yield'
                    },
                    plotOptions: {
                        line: {
                            marker: {
                                enabled: false
                            }
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    xAxis: {
                        type: 'datetime',
                        labels: {
                            format: '{value: %e %b, %Y}'
                        }
                    },
                    yAxis: {
                        title: {
                            text: 'Yield (t/ha)'
                        },
                        min: 0,
                        max: 100
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.y:.1f} t/ha</b>',
                        valueSuffix: ' (t/ha)'
                    },
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                        layout: 'horizontal',
                        squareSymbol: true
                    },
                    series: [
                        {
                            name: 'Total Yield Forecast',
                            showInLegend: false,
                            color: '#7cb5ec',
                            data: totalYieldProfile
                        },
                        {
                            name: "Scenario 1",
                            colorByPoint: false,
                            showInLegend: true,
                            color: '#522bb1',
                            marker: {
                                enabled: true,
                                symbol: "circle"
                            },
                            data: [scenarioOneData]
                        },
                        {
                            name: "Scenario 2",
                            colorByPoint: false,
                            showInLegend: true,
                            color: '#1e87a0',
                            marker: {
                                enabled: true,
                                symbol: "circle"
                            },
                            data: [scenarioTwoData]
                        }
                    ]
                };
            },

            getYieldDistributionChartConfig: function (scenario_1, scenario_2, partitionLabels) {
                return {
                    chart: {
                        type: 'column'
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: 'Yield distribution'
                    },
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                        layout: 'horizontal'
                    },

                    xAxis: {
                        title: {
                            enabled: false
                        },
                        "labels": {
                            enable: false,
                            x: -10
                        },
                        categories: partitionLabels
                    },

                    yAxis: {
                        allowDecimals: false,
                        title: {
                            enabled: false,
                            text: 'Tuber yield dist (t/ha)'
                        }
                    },
                    tooltip: {
                        headerFormat: '',
                        formatter: function () {
                            return this.series.name + ' (' + this.key + ') : <b>' + $filter('number')(this.y, 1) + ' t/ha</b><br/>';
                        }
                    },
                    series: [
                        {
                            name: "Scenario 1",
                            color: '#522bb1',
                            data: scenario_1
                        },
                        {
                            name: "Scenario 2",
                            color: '#1e87a0',
                            data: scenario_2
                        }
                    ]
                }
            },

        };

        return helpers;

    }

})(angular);
