(function (angular) {
    "use strict";
    angular.module("PotatoApp")
        .factory("MileStoneSummaryFactory", MileStoneSummaryFactory);

    function MileStoneSummaryFactory($resource) {
        var url = "/api/potato/dashboard/";
        var queryParamDict = {
            farm: "@farm"
        };

        return $resource(url, {},
            {
                query: {
                    params: queryParamDict,
                    url: url,
                    isArray: true
                }
            }
        );
    }
})(angular);