(function (angular) {
    "use strict";
    angular.module('PotatoApp')
        .factory('CanopyCoverDetailFactory', ["$resource", CanopyCoverDetailFactory]);

    function CanopyCoverDetailFactory($resource) {
        var url = "/api/v1.0/potato/canopy-summary/";

        return $resource(url, {},
            {
                get: {
                    params: {field_plot_crop_slug: '@field_plot_crop_slug'},
                    url: url + ':field_plot_crop_slug/',
                    method: 'GET'
                }
            }
        );
    }

})(angular);