(function (angular) {
    "use strict";
    angular.module('PotatoApp')
        .factory('BurndownModellingScenariosForm', BurndownModellingScenariosFormFactory);

    BurndownModellingScenariosFormFactory.$inject = [];

    function BurndownModellingScenariosFormFactory() {

        var helpers = {

            fetch: function (dateExtremes, defaultDateExtremes) {
                return [
                    {
                        className: 'layout-row',
                        fieldGroup: [
                            {
                                key: "scenario_1",
                                className: "flex-50",
                                type: "datepicker",
                                defaultValue: new Date(defaultDateExtremes[0]),
                                templateOptions: {
                                    label: "Scenario 1",
                                    required: true,
                                    minDate: new Date(dateExtremes[0]),
                                    maxDate: new Date(dateExtremes[1]),
                                    onChange: function ($viewValue, $modelValue, $scope) {
                                        $scope.$emit("scenarioChange")
                                    }
                                }
                            },
                            {
                                key: "scenario_2",
                                className: "flex-50",
                                type: "datepicker",
                                defaultValue: new Date(defaultDateExtremes[1]),
                                templateOptions: {
                                    label: "Scenario 2",
                                    required: true,
                                    minDate: new Date(dateExtremes[0]),
                                    maxDate: new Date(dateExtremes[1]),
                                    onChange: function ($viewValue, $modelValue, $scope) {
                                        $scope.$emit("scenarioChange")
                                    }
                                }
                            }
                        ]
                    }
                ]
            },

        };

        return helpers;

    }

})(angular);
