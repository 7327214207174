(function (angular) {
    "use strict";
    angular.module("PotatoApp")
        .controller("PotatoPlantingConfigurationController", PotatoPlantingConfigurationController);

    PotatoPlantingConfigurationController.$inject = ["$scope", "$rootScope", "$filter", "$mdDialog", "$khMessage",
        "NgTableParams", "plot_slug", "planting_config_slug", "PotatoFieldPlotPlantingSystem"];

    function PotatoPlantingConfigurationController($scope, $rootScope, $filter, $mdDialog, $khMessage, NgTableParams,
                                                   plot_slug, planting_config_slug, PotatoFieldPlotPlantingSystem) {
        var ctrl = this;

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Initialisation and configuration
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        $scope.plot_slug = plot_slug;
        $scope.planting_config_slug = planting_config_slug;
        $scope.plantingConfigForm = potatoPlantingConfigurationForm($scope.potato_planting_systems);
        $scope.plantingConfig = {};
        $scope.submit = submitPlantingConfig;

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Controller API
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////


        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Internal API: Function definitions
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        function potatoPlantingConfigurationForm(planting_systems) {
            return [
                {
                    className: "flex-100",
                    key: "plantingSystem",
                    type: "searchable_select",
                    defaultValue: planting_config_slug ? planting_config_slug : null,
                    templateOptions: {
                        label: "Planting system",
                        valueProp: "slug",
                        required: true,
                        options: planting_systems
                    }
                }
            ];
        }

        function submitPlantingConfig(slug, formData) {
            if ($scope.planting_config_slug) {
                PotatoFieldPlotPlantingSystem.update({
                    plotSlug: slug
                }, {
                    fieldplotcrop: slug, planting_system: formData.plantingSystem
                }, function (success) {
                    $khMessage.show($filter("translate")("updated_successfully"), 5000);
                    var data = {
                        "fieldplotcrop": success.fieldplotcrop,
                        "planting_system": success.planting_system
                    }
                    $mdDialog.hide(data);
                }, function (error) {
                    $khMessage.show("Failed to record planting system", 5000);
                    console.log(error);
                });
            } else {
                PotatoFieldPlotPlantingSystem.save({
                    fieldplotcrop: slug,
                    planting_system: formData.plantingSystem
                }, function (success) {
                    $khMessage.show($filter("translate")("created_successfully"), 5000);
                    $mdDialog.hide(success);
                }, function (error) {
                    $khMessage.show("Failed to record planting system", 5000);
                    console.log(error);
                });
            }
        }
    }

})(angular);
