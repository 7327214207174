(function (angular) {
    "use strict";
    angular.module('PotatoApp')
        .factory('BurndownModellingHelpers', BurndownModellingHelpersFactory);

    BurndownModellingHelpersFactory.$inject = [];

    function BurndownModellingHelpersFactory() {

        var helpers = {

            extractChartDataFromModelOutput: function (modelOutput, date_1, date_2) {
                var partitionLabels = helpers.extractPartitionLabels(modelOutput.yield_distribution_profile);
                return {
                    scenarioOneDate: date_1,
                    scenarioTwoDate: date_2,
                    totalYields: modelOutput.total_yields,
                    partitionLabels: partitionLabels,
                    scenarioOnePartitions: helpers.extractPartitionDataForDate(partitionLabels, modelOutput.yield_distribution_profile, date_1),
                    scenarioTwoPartitions: helpers.extractPartitionDataForDate(partitionLabels, modelOutput.yield_distribution_profile, date_2)
                }
            },

            extractDateExtremes: function (modelOutput) {
                var totalYields = modelOutput.total_yields;
                var minDate = totalYields[0][0];
                var maxDate = totalYields[totalYields.length - 1][0];
                return [minDate, maxDate];
            },

            extractDefaultDateExtremes: function (modelOutput) {
                var totalYields = modelOutput.total_yields;
                var scenarioOneDefaultDate = helpers.extractMinDate(totalYields[0][0], totalYields[totalYields.length - 1][0]);
                var scenarioTwoDefaultDate = helpers.extractMaxDate(totalYields[totalYields.length - 1][0]);
                return [scenarioOneDefaultDate, scenarioTwoDefaultDate];
            },

            extractMinDate: function (minDate, maxDate) {
                if (new Date(minDate) > new Date()) {
                    return minDate;
                }
                else if (new Date(maxDate) < new Date()) {
                    return minDate;
                }
                else {
                    var currentDate = new Date();
                    return Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate());
                }
            },

            extractMaxDate: function (maxDate) {
                var nextWeekDate = new Date(new Date().setDate(new Date().getDate() + 7));
                if (new Date(maxDate) < nextWeekDate) {
                    return maxDate;
                }
                else {
                    return Date.UTC(nextWeekDate.getUTCFullYear(), nextWeekDate.getUTCMonth(), nextWeekDate.getUTCDate());
                }

            },


            extractPartitionLabels: function (partitionedYields) {
                var partitionLabels = [];
                angular.forEach(partitionedYields, function (yieldElement, yieldIdx, yieldObj) {
                    partitionLabels.push(yieldElement["partition_name"]);
                });
                return partitionLabels;
            },

            extractPartitionDataForDate: function (partitionLabels, partitionedYields, targetDate) {
                var firstPartition = partitionedYields[0].data;
                var targetIdx = null;
                var dataForDate = [];
                angular.forEach(firstPartition, function (element, idx, obj) {
                    var iterDate = element[0];
                    if (iterDate === targetDate) {
                        targetIdx = idx;
                    }
                });
                angular.forEach(partitionLabels, function (label, idx, obj) {
                    angular.forEach(partitionedYields, function (yieldElement, yieldIdx, yieldObj) {
                        if (yieldElement.partition_name === label) {
                            var dataPoint = yieldElement.data[targetIdx][1];
                            dataForDate.push(dataPoint);
                        }
                    })
                });
                return dataForDate;
            }
        };

        return helpers;
    }

})(angular);

