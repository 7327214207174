(function (angular) {
    "use strict";
    angular.module("PotatoApp")
        .controller("EditTempEmergenceController", EditTempEmergenceController);

    EditTempEmergenceController.$inject = ["$scope", "$rootScope", "$filter", "$mdDialog", "$khMessage", "plot_slug",
        "emergence_record_id", "existing_date", "emergence_type", "min_date",  "DateTimeHelperFactory", "TempEmergenceFactory"];

    function EditTempEmergenceController($scope, $rootScope, $filter, $mdDialog, $khMessage, plot_slug,
                                         emergence_record_id, existing_date, emergence_type, min_date, DateTimeHelperFactory,
                                         TempEmergenceFactory) {

        var ctrl = this;

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Initialisation and configuration
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        $scope.min_date = min_date;
        $scope.emergence_date_form = temporaryEmergenceForm();
        $scope.emergenceDetail = {emergenceDate: new Date(existing_date)};

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Controller API
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        $scope.submitEmergence = submitEmergence;

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Internal API: Function definitions
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        function temporaryEmergenceForm() {
            return [
                {
                    className: "flex-100",
                    key: "emergenceDate",
                    type: "datepicker",
                    templateOptions: {
                        label: "Emergence date",
                        required: true,
                        minDate: new Date(min_date)
                    }
                }
            ];
        }

        function submitEmergence(formData) {
            var utcTime = DateTimeHelperFactory.convertDatePickerToDateStringWithoutHourAdjustment(formData.emergenceDate);
            var submissionObject = {
                fieldplotcrop: plot_slug,
                emergence_date: utcTime,
                emergence_type: emergence_type
            };
            TempEmergenceFactory.update({emergenceRecordId: emergence_record_id}, submissionObject, function (success) {
                $khMessage.show($filter("translate")("updated_successfully"), 5000);
                $mdDialog.hide(success);
            }, function (error) {
                console.log("error ", error);
            });
        }

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Watchers and Filter routines
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    }

})(angular);
