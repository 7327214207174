(function (angular) {
    "use strict";
    angular.module('PotatoApp')
        .controller('SensitivityAnalysisController', ['$scope', '$filter', '$rootScope', SensitivityAnalysisController]);

    function SensitivityAnalysisController($scope, $filter, $rootScope) {

        $rootScope.page_id = "potatodashboard";
        $rootScope.sub_app_page_id = "potatodashboard_scenario";
        $rootScope.view_only_irrigation = false;
        var legendAgronomicOptimum = "Agronomic optimum";
        var legendCurrentSelection = "Selected date";
        $scope.crops = ['Field-crop 1', 'Field-crop 2', 'Field-crop 3', 'Field-crop 4'];
        $scope.revenueValues = {
            "<45": null,
            "45-55": null,
            "55-75": null,
            ">75": null
        };
        $scope.searchTerm;
        $scope.count = 0;
        $scope.pushIntoTuberYieldDisributionChart = function () {
            $scope.TuberYieldDistributionChartConfig.series.push(
                {
                    name: legendCurrentSelection,
                    color: '#43A047',
                    data: []
                }
            );
            $scope.count++;
        };
        $scope.clearSearchTerm = function () {
            $scope.searchTerm = '';
        };

        $scope.YieldModelChartConfig = getYieldModelChartConfig();
        $scope.TuberYieldDistributionChartConfig = {
            chart: {
                type: 'column'
            },
            credits: {
                enabled: false
            },
            title: {
                text: 'Tuber yield distribution'
            },
            legend: {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal'
            },

            xAxis: {
                title: {
                    enabled: false
                },
                "labels": {
                    enable: false,
                    x: -10
                },
                categories: ["<45mm", '45-55mm', '55-75mm', '>75mm'],
            },

            yAxis: {
                allowDecimals: false,
                title: {
                    enabled: false,
                    text: 'Tuber yield dist (t/ha)'
                }
            },
            tooltip: {
                headerFormat: '',
                formatter: function () {
                    return this.series.name + ' (' + this.key + ') : <b>' + $filter('number')(this.y, 0) + ' t/ha</b><br/>';
                }
            },
            series: [
                {
                    name: legendAgronomicOptimum,
                    color: '#DD2C00',
                    data: [4.8, 14.3, 20.8, 19.6]
                }
            ]
        };
        $scope.RevenuePerHectarebyTuberSize = {
            chart: {
                type: 'column'
            },
            credits: {
                enabled: false
            },
            title: {
                text: 'Revenue per hectare by tuber size (£/ha)'
            },
            legend: {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal'
            },

            xAxis: {
                title: {
                    enabled: false
                },
                labels: {
                    enable: false,
                    x: -10
                },
                categories: ['<45mm', '45-55mm', '55-75mm', '>75mm']
            },

            yAxis: {
                allowDecimals: false,
                title: {
                    enabled: false,
                    text: 'Tuber yield distribution (t/ha)'
                }
            },
            tooltip: {
                headerFormat: '',
                formatter: function () {
                    return this.series.name + ' (' + this.key + ') : <b>£' + $filter('number')(this.y, 0) + '/ ha</b><br/>';
                }
            },
            series: [
                {
                    name: legendAgronomicOptimum,
                    color: '#DD2C00',
                    data: [4.8, 14.3, 20.8, 19.6]
                }, {
                    name: legendCurrentSelection,
                    color: '#43A047',
                    data: [60, 40, 20, 30]
                }]
        };
        $scope.RevenuePerHectarebyTuberSizeTotal = {
            chart: {
                type: 'bar'
            },
            title: {
                text: 'Revenue per hectare by tuber size (£/ha) '
            },
            xAxis: {
                labels: {
                    enabled: false
                },
                title: {
                    text: null
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: '',
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '{series.name}: <b>£{point.y:.1f} / ha</b><br/>'
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                        format: "£{y:.1f}"
                    }
                }
            },
            legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                floating: false,
                borderWidth: 1,
                backgroundColor: ((Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'),
                shadow: true
            },
            credits: {
                enabled: false
            },
            series: [
                {
                    name: legendAgronomicOptimum,
                    color: '#DD2C00',
                    data: [107]
                }, {
                    name: legendCurrentSelection,
                    color: '#43A047',
                    data: [133]
                }
            ]
        };

        $scope.updateTuberYieldDistributionChart = function (date) {
            for (var i = 0; i < $scope.data.yield_distributions_profile.length; i++) {
                if (date == $filter('date')(new Date($scope.data.yield_distributions_profile[i].forecast_date), 'yyyy-MM-dd')) {
                    $scope.TuberYieldDistributionChartConfig.xAxis.categories = [];
                    $scope.TuberYieldDistributionChartConfig.series[1].data = [];
                    for (var j = 1; j < $scope.data.yield_distributions_profile[i].forecasts.length; j++) {
                        $scope.TuberYieldDistributionChartConfig.xAxis.categories.push($scope.data.yield_distributions_profile[i].forecasts[j].grade + "mm");
                        $scope.TuberYieldDistributionChartConfig.series[1].data.push($scope.data.yield_distributions_profile[i].forecasts[j].value);
                    }
                }
            }
        };
        $scope.updateRevenuePerHectarebyTuberSize = function (date) {
            for (var i = 0; i < $scope.data.yield_distributions_profile.length; i++) {
                if (date == $filter('date')(new Date($scope.data.yield_distributions_profile[i].forecast_date), 'yyyy-MM-dd')) {
                    $scope.RevenuePerHectarebyTuberSize.xAxis.categories = [];
                    $scope.RevenuePerHectarebyTuberSize.series[1].data = [];
                    for (var j = 1; j < $scope.data.yield_distributions_profile[i].forecasts.length; j++) {
                        console.log($scope.data.yield_distributions_profile[i]);
                        $scope.RevenuePerHectarebyTuberSize.xAxis.categories.push($scope.data.yield_distributions_profile[i].forecasts[j].grade);
                        $scope.RevenuePerHectarebyTuberSize.series[1].data.push($scope.data.yield_distributions_profile[i].forecasts[j].value);
                    }
                }
                else if ($scope.revenueValues["<45"] || $scope.revenueValues["45-55"] || $scope.revenueValues["55-75"] || $scope.revenueValues[">75"]) {
                    $scope.RevenuePerHectarebyTuberSize.xAxis.categories = [];
                    $scope.RevenuePerHectarebyTuberSize.series[0].data = [];
                    $scope.RevenuePerHectarebyTuberSize.series[1].data = [];
                    for (var j = 1; j < $scope.data.yield_distributions_profile[i].forecasts.length; j++) {
                        $scope.RevenuePerHectarebyTuberSize.xAxis.categories.push($scope.data.yield_distributions_profile[i].forecasts[j].grade + "mm");
                        $scope.RevenuePerHectarebyTuberSize.series[0].data.push($scope.data.agronomic_optimum.forecast.full_forecast[j].value * $scope.revenueValues[$scope.data.yield_distributions_profile[i].forecasts[j].grade]);
                        if ($scope.selectedDate) {
                            $scope.RevenuePerHectarebyTuberSize.series[1].data.push($scope.TuberYieldDistributionChartConfig.series[1].data[j - 1] * $scope.revenueValues[$scope.data.yield_distributions_profile[i].forecasts[j].grade]);
                        }
                    }
                }
            }
            $scope.updateRevenuePerHectarebyTuberSizeTotal(date);
        };

        $scope.sumOfRevenueValues = function (series) {
            if (series == 0) {
                $scope.modelledValue = $scope.RevenuePerHectarebyTuberSize.series[0].data.reduce(function (a, b) {
                    return a + b;
                }, 0);
                return $scope.modelledValue;
            } else {
                $scope.agronomicOptimumValue = $scope.RevenuePerHectarebyTuberSize.series[1].data.reduce(function (a, b) {
                    return a + b;
                }, 0);
                $scope.differenceValue = $scope.agronomicOptimumValue - $scope.modelledValue;
                return $scope.agronomicOptimumValue;
            }
        };

        $scope.showRevenuemodellerChart = function () {
            return !!($scope.revenueValues["<45"] || $scope.revenueValues["45-55"] || $scope.revenueValues["55-75"] || $scope.revenueValues[">75"]);
        };

        $scope.updateRevenuePerHectarebyTuberSizeTotal = function (date) {
            for (var i = 0; i < $scope.data.yield_distributions_profile.length; i++) {
                if ($scope.revenueValues["<45"] || $scope.revenueValues["45-55"] || $scope.revenueValues["55-75"] || $scope.revenueValues[">75"]) {
                    $scope.RevenuePerHectarebyTuberSizeTotal.series[0].data = [];
                    $scope.RevenuePerHectarebyTuberSizeTotal.series[1].data = [];
                    for (var j = 0; j < $scope.data.yield_distributions_profile[i].forecasts.length; j++) {
                        if ($scope.data.yield_distributions_profile[i].forecasts[j].grade == 'total') {
                            $scope.RevenuePerHectarebyTuberSizeTotal.series[0].data.push($scope.sumOfRevenueValues(0));
                            $scope.RevenuePerHectarebyTuberSizeTotal.series[1].data.push($scope.sumOfRevenueValues(1));
                        }
                    }
                }
                else if (date == $filter('date')(new Date($scope.data.yield_distributions_profile[i].forecast_date), 'yyyy-MM-dd')) {
                    $scope.RevenuePerHectarebyTuberSizeTotal.series[1].data = [];
                    for (var j = 0; j < $scope.data.yield_distributions_profile[i].forecasts.length; j++) {
                        if ($scope.data.yield_distributions_profile[i].forecasts[j].grade == 'total') {
                            $scope.RevenuePerHectarebyTuberSizeTotal.series[1].data.push($scope.data.yield_distributions_profile[i].forecasts[j].value);
                        }
                    }
                }
            }
        };

        function updateYieldProfile(selectedDate) {
            if ($scope.YieldModelChartConfig.series.length === 2) {
                $scope.YieldModelChartConfig.series.push(
                    {
                        name: legendCurrentSelection,
                        colorByPoint: false,
                        showInLegend: true,
                        color: "green",
                        marker: {
                            enabled: true,
                            symbol: "triangle"
                        },
                        data: getSelectedDateTotal(selectedDate)
                    }
                )
            } else if ($scope.YieldModelChartConfig.series.length === 3) {
                $scope.YieldModelChartConfig.series[2] = {
                    name: legendCurrentSelection,
                    colorByPoint: false,
                    showInLegend: true,
                    color: "green",
                    marker: {
                        enabled: true,
                        symbol: "triangle"
                    },
                    data: getSelectedDateTotal(selectedDate)
                }
            }
        }

        function getSelectedDateTotal(selectedDate) {
            var selectedDateData = null;
            angular.forEach($scope.data.total_yields, function (value, key) {
                if (value.date === selectedDate) {
                    selectedDateData = [
                        {
                            x: Date.parse(selectedDate),
                            y: value.value
                        }
                    ]
                }
            });
            if (selectedDateData) {
                return selectedDateData
            }
            else {
                return []
            }

        }

        function getExtremeDates(data) {
            // Need to include dates range in the processed output to populate these values
            $scope.minDate = new Date(2016, 6, 27);
            $scope.maxDate = addDays(new Date(2016, 6, 27), 96);
        }

        function addDays(date, days) {
            var result = new Date(date);
            result.setDate(result.getDate() + days);
            return result;
        }

        function getYieldModelChartConfig() {
            return {
                title: {
                    text: 'Yield model output'
                },
                plotOptions: {
                    line: {
                        marker: {
                            enabled: false
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        format: '{value: %e %b, %Y}'
                    }
                },
                yAxis: {
                    title: {
                        text: 'Yield (t/ha)'
                    },
                    min: 0,
                    max: 100
                },
                tooltip: {
                    valueSuffix: ' (t/ha)'
                },
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal',
                    squareSymbol: true
                },
                series: [
                    {
                        name: 'Mean Yield Forecast',
                        showInLegend: false,
                        data: [[1459468800000.0, 0.0],
                            [1459900800000.0, 0.0],
                            [1460332800000.0, 0.0],
                            [1460764800000.0, 0.0],
                            [1461196800000.0, 0.0],
                            [1461628800000.0, 0.0],
                            [1462060800000.0, 0.0],
                            [1462492800000.0, 0.0],
                            [1462924800000.0, 0.0],
                            [1463356800000.0, 0.0],
                            [1463788800000.0, 0.0],
                            [1464220800000.0, 0.0],
                            [1464652800000.0, 0.0],
                            [1465084800000.0, 0.0],
                            [1465516800000.0, 0.0],
                            [1465948800000.0, 0.0],
                            [1466380800000.0, 0.0],
                            [1466812800000.0, 0.0],
                            [1467244800000.0, 2.3],
                            [1467676800000.0, 6.1],
                            [1468108800000.0, 10.4],
                            [1468540800000.0, 14.8],
                            [1468972800000.0, 19.3],
                            [1469404800000.0, 23.7],
                            [1469836800000.0, 28.0],
                            [1470268800000.0, 32.2],
                            [1470700800000.0, 36.2],
                            [1471132800000.0, 40.1],
                            [1471392000000.0, 42.3],
                            [1471824000000.0, 45.9],
                            [1472256000000.0, 49.3],
                            [1472688000000.0, 52.5],
                            [1473120000000.0, 55.5],
                            [1473552000000.0, 57.9],
                            [1473984000000.0, 59.5],
                            [1474416000000.0, 60.1],
                            [1474848000000.0, 60.3],
                            [1475280000000.0, 60.3],
                            [1475712000000.0, 60.3],
                            [1476144000000.0, 60.3],
                            [1476576000000.0, 60.3],
                            [1477008000000.0, 60.3],
                            [1477440000000.0, 60.3]]
                    },
                    {
                        name: legendAgronomicOptimum,
                        colorByPoint: false,
                        showInLegend: true,
                        color: "red",
                        marker: {
                            enabled: true,
                            symbol: "circle"
                        },
                        data: [
                            {
                                x: 1473120000000.0,
                                y: 55.5
                            }
                        ]
                    }
                ]
            };
        }

        $scope.changeChartData = function (selectedCrop, selectedDate) {
            $scope.selectedDate = selectedDate;
            if ($scope.count == 0) {
                $scope.pushIntoTuberYieldDisributionChart();
            }
            selectedDate = $filter('date')(new Date(selectedDate), 'yyyy-MM-dd');
            updateYieldProfile(selectedDate);
            $scope.updateTuberYieldDistributionChart(selectedDate);
            $scope.updateRevenuePerHectarebyTuberSize(selectedDate);
        };
        $scope.data = {
            "agronomic_optimum": {
                "date": "2016-09-16",
                "forecast": {
                    "full_forecast": [
                        {
                            "grade": "total",
                            "value": 59.5
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 14.3
                        },
                        {
                            "grade": "55-75",
                            "value": 20.8
                        },
                        {
                            "grade": ">75",
                            "value": 19.6
                        }
                    ],
                    "mid_grades": 35.1
                }
            },
            "total_yields": [
                {
                    "date": "2016-06-27",
                    "value": 0.9
                },
                {
                    "date": "2016-06-26",
                    "value": 0.5
                },
                {
                    "date": "2016-06-25",
                    "value": 0.0
                },
                {
                    "date": "2016-06-24",
                    "value": 0.0
                },
                {
                    "date": "2016-06-23",
                    "value": 0.0
                },
                {
                    "date": "2016-06-22",
                    "value": 0.0
                },
                {
                    "date": "2016-06-21",
                    "value": 0.0
                },
                {
                    "date": "2016-06-20",
                    "value": 0.0
                },
                {
                    "date": "2016-06-29",
                    "value": 1.8
                },
                {
                    "date": "2016-06-28",
                    "value": 1.4
                },
                {
                    "date": "2016-09-28",
                    "value": 60.3
                },
                {
                    "date": "2016-09-29",
                    "value": 60.3
                },
                {
                    "date": "2016-09-22",
                    "value": 60.1
                },
                {
                    "date": "2016-09-23",
                    "value": 60.2
                },
                {
                    "date": "2016-09-20",
                    "value": 60.0
                },
                {
                    "date": "2016-09-21",
                    "value": 60.1
                },
                {
                    "date": "2016-09-26",
                    "value": 60.3
                },
                {
                    "date": "2016-09-27",
                    "value": 60.3
                },
                {
                    "date": "2016-09-24",
                    "value": 60.2
                },
                {
                    "date": "2016-09-25",
                    "value": 60.3
                },
                {
                    "date": "2016-04-29",
                    "value": 0.0
                },
                {
                    "date": "2016-04-28",
                    "value": 0.0
                },
                {
                    "date": "2016-05-31",
                    "value": 0.0
                },
                {
                    "date": "2016-07-31",
                    "value": 28.8
                },
                {
                    "date": "2016-07-30",
                    "value": 28.0
                },
                {
                    "date": "2016-05-30",
                    "value": 0.0
                },
                {
                    "date": "2016-08-30",
                    "value": 51.2
                },
                {
                    "date": "2016-08-31",
                    "value": 51.9
                },
                {
                    "date": "2016-07-27",
                    "value": 25.4
                },
                {
                    "date": "2016-09-30",
                    "value": 60.3
                },
                {
                    "date": "2016-04-23",
                    "value": 0.0
                },
                {
                    "date": "2016-04-22",
                    "value": 0.0
                },
                {
                    "date": "2016-04-24",
                    "value": 0.0
                },
                {
                    "date": "2016-08-29",
                    "value": 50.6
                },
                {
                    "date": "2016-08-28",
                    "value": 49.9
                },
                {
                    "date": "2016-08-25",
                    "value": 47.9
                },
                {
                    "date": "2016-08-24",
                    "value": 47.3
                },
                {
                    "date": "2016-08-27",
                    "value": 49.3
                },
                {
                    "date": "2016-08-26",
                    "value": 48.6
                },
                {
                    "date": "2016-08-21",
                    "value": 45.2
                },
                {
                    "date": "2016-08-20",
                    "value": 44.5
                },
                {
                    "date": "2016-08-23",
                    "value": 46.6
                },
                {
                    "date": "2016-08-22",
                    "value": 45.9
                },
                {
                    "date": "2016-05-26",
                    "value": 0.0
                },
                {
                    "date": "2016-05-27",
                    "value": 0.0
                },
                {
                    "date": "2016-05-24",
                    "value": 0.0
                },
                {
                    "date": "2016-05-25",
                    "value": 0.0
                },
                {
                    "date": "2016-09-08",
                    "value": 56.5
                },
                {
                    "date": "2016-05-23",
                    "value": 0.0
                },
                {
                    "date": "2016-05-20",
                    "value": 0.0
                },
                {
                    "date": "2016-05-21",
                    "value": 0.0
                },
                {
                    "date": "2016-09-04",
                    "value": 54.3
                },
                {
                    "date": "2016-09-05",
                    "value": 54.9
                },
                {
                    "date": "2016-09-06",
                    "value": 55.5
                },
                {
                    "date": "2016-09-07",
                    "value": 56.0
                },
                {
                    "date": "2016-09-01",
                    "value": 52.5
                },
                {
                    "date": "2016-05-28",
                    "value": 0.0
                },
                {
                    "date": "2016-09-03",
                    "value": 53.7
                },
                {
                    "date": "2016-10-08",
                    "value": 60.3
                },
                {
                    "date": "2016-10-09",
                    "value": 60.3
                },
                {
                    "date": "2016-10-06",
                    "value": 60.3
                },
                {
                    "date": "2016-05-22",
                    "value": 0.0
                },
                {
                    "date": "2016-10-04",
                    "value": 60.3
                },
                {
                    "date": "2016-10-05",
                    "value": 60.3
                },
                {
                    "date": "2016-10-02",
                    "value": 60.3
                },
                {
                    "date": "2016-10-03",
                    "value": 60.3
                },
                {
                    "date": "2016-09-09",
                    "value": 56.9
                },
                {
                    "date": "2016-08-10",
                    "value": 37.0
                },
                {
                    "date": "2016-08-11",
                    "value": 37.8
                },
                {
                    "date": "2016-08-12",
                    "value": 38.5
                },
                {
                    "date": "2016-08-13",
                    "value": 39.3
                },
                {
                    "date": "2016-08-14",
                    "value": 40.1
                },
                {
                    "date": "2016-08-15",
                    "value": 41.2
                },
                {
                    "date": "2016-08-16",
                    "value": 42.1
                },
                {
                    "date": "2016-08-17",
                    "value": 42.3
                },
                {
                    "date": "2016-08-18",
                    "value": 43.0
                },
                {
                    "date": "2016-08-19",
                    "value": 43.7
                },
                {
                    "date": "2016-09-13",
                    "value": 58.5
                },
                {
                    "date": "2016-09-12",
                    "value": 58.2
                },
                {
                    "date": "2016-09-11",
                    "value": 57.9
                },
                {
                    "date": "2016-09-10",
                    "value": 57.4
                },
                {
                    "date": "2016-09-17",
                    "value": 59.6
                },
                {
                    "date": "2016-09-16",
                    "value": 59.5
                },
                {
                    "date": "2016-09-15",
                    "value": 59.2
                },
                {
                    "date": "2016-09-14",
                    "value": 58.9
                },
                {
                    "date": "2016-04-21",
                    "value": 0.0
                },
                {
                    "date": "2016-04-20",
                    "value": 0.0
                },
                {
                    "date": "2016-09-19",
                    "value": 59.9
                },
                {
                    "date": "2016-09-18",
                    "value": 59.7
                },
                {
                    "date": "2016-04-25",
                    "value": 0.0
                },
                {
                    "date": "2016-04-30",
                    "value": 0.0
                },
                {
                    "date": "2016-04-27",
                    "value": 0.0
                },
                {
                    "date": "2016-04-26",
                    "value": 0.0
                },
                {
                    "date": "2016-07-17",
                    "value": 16.6
                },
                {
                    "date": "2016-07-16",
                    "value": 15.7
                },
                {
                    "date": "2016-07-19",
                    "value": 18.4
                },
                {
                    "date": "2016-10-19",
                    "value": 60.3
                },
                {
                    "date": "2016-09-02",
                    "value": 53.1
                },
                {
                    "date": "2016-07-18",
                    "value": 17.5
                },
                {
                    "date": "2016-10-15",
                    "value": 60.3
                },
                {
                    "date": "2016-10-14",
                    "value": 60.3
                },
                {
                    "date": "2016-10-17",
                    "value": 60.3
                },
                {
                    "date": "2016-05-29",
                    "value": 0.0
                },
                {
                    "date": "2016-10-11",
                    "value": 60.3
                },
                {
                    "date": "2016-10-10",
                    "value": 60.3
                },
                {
                    "date": "2016-10-13",
                    "value": 60.3
                },
                {
                    "date": "2016-10-18",
                    "value": 60.3
                },
                {
                    "date": "2016-07-02",
                    "value": 3.8
                },
                {
                    "date": "2016-07-03",
                    "value": 4.6
                },
                {
                    "date": "2016-08-05",
                    "value": 33.0
                },
                {
                    "date": "2016-07-01",
                    "value": 3.1
                },
                {
                    "date": "2016-07-06",
                    "value": 7.0
                },
                {
                    "date": "2016-07-07",
                    "value": 7.8
                },
                {
                    "date": "2016-07-04",
                    "value": 5.3
                },
                {
                    "date": "2016-07-05",
                    "value": 6.1
                },
                {
                    "date": "2016-05-01",
                    "value": 0.0
                },
                {
                    "date": "2016-05-02",
                    "value": 0.0
                },
                {
                    "date": "2016-05-03",
                    "value": 0.0
                },
                {
                    "date": "2016-05-04",
                    "value": 0.0
                },
                {
                    "date": "2016-05-05",
                    "value": 0.0
                },
                {
                    "date": "2016-05-06",
                    "value": 0.0
                },
                {
                    "date": "2016-05-07",
                    "value": 0.0
                },
                {
                    "date": "2016-04-18",
                    "value": 0.0
                },
                {
                    "date": "2016-04-19",
                    "value": 0.0
                },
                {
                    "date": "2016-04-14",
                    "value": 0.0
                },
                {
                    "date": "2016-04-15",
                    "value": 0.0
                },
                {
                    "date": "2016-04-16",
                    "value": 0.0
                },
                {
                    "date": "2016-04-17",
                    "value": 0.0
                },
                {
                    "date": "2016-04-10",
                    "value": 0.0
                },
                {
                    "date": "2016-04-11",
                    "value": 0.0
                },
                {
                    "date": "2016-04-12",
                    "value": 0.0
                },
                {
                    "date": "2016-04-13",
                    "value": 0.0
                },
                {
                    "date": "2016-10-16",
                    "value": 60.3
                },
                {
                    "date": "2016-06-18",
                    "value": 0.0
                },
                {
                    "date": "2016-06-19",
                    "value": 0.0
                },
                {
                    "date": "2016-06-16",
                    "value": 0.0
                },
                {
                    "date": "2016-06-17",
                    "value": 0.0
                },
                {
                    "date": "2016-06-14",
                    "value": 0.0
                },
                {
                    "date": "2016-06-15",
                    "value": 0.0
                },
                {
                    "date": "2016-06-12",
                    "value": 0.0
                },
                {
                    "date": "2016-06-13",
                    "value": 0.0
                },
                {
                    "date": "2016-06-10",
                    "value": 0.0
                },
                {
                    "date": "2016-06-11",
                    "value": 0.0
                },
                {
                    "date": "2016-10-20",
                    "value": 60.3
                },
                {
                    "date": "2016-10-21",
                    "value": 60.3
                },
                {
                    "date": "2016-10-22",
                    "value": 60.3
                },
                {
                    "date": "2016-10-23",
                    "value": 60.3
                },
                {
                    "date": "2016-10-24",
                    "value": 60.3
                },
                {
                    "date": "2016-10-25",
                    "value": 60.3
                },
                {
                    "date": "2016-10-26",
                    "value": 60.3
                },
                {
                    "date": "2016-10-12",
                    "value": 60.3
                },
                {
                    "date": "2016-07-11",
                    "value": 11.3
                },
                {
                    "date": "2016-08-07",
                    "value": 34.6
                },
                {
                    "date": "2016-07-13",
                    "value": 13.0
                },
                {
                    "date": "2016-07-12",
                    "value": 12.2
                },
                {
                    "date": "2016-07-15",
                    "value": 14.8
                },
                {
                    "date": "2016-07-14",
                    "value": 13.9
                },
                {
                    "date": "2016-05-19",
                    "value": 0.0
                },
                {
                    "date": "2016-05-18",
                    "value": 0.0
                },
                {
                    "date": "2016-05-17",
                    "value": 0.0
                },
                {
                    "date": "2016-05-16",
                    "value": 0.0
                },
                {
                    "date": "2016-05-15",
                    "value": 0.0
                },
                {
                    "date": "2016-05-14",
                    "value": 0.0
                },
                {
                    "date": "2016-05-13",
                    "value": 0.0
                },
                {
                    "date": "2016-05-12",
                    "value": 0.0
                },
                {
                    "date": "2016-05-11",
                    "value": 0.0
                },
                {
                    "date": "2016-05-10",
                    "value": 0.0
                },
                {
                    "date": "2016-04-03",
                    "value": 0.0
                },
                {
                    "date": "2016-04-02",
                    "value": 0.0
                },
                {
                    "date": "2016-04-01",
                    "value": 0.0
                },
                {
                    "date": "2016-08-04",
                    "value": 32.2
                },
                {
                    "date": "2016-04-07",
                    "value": 0.0
                },
                {
                    "date": "2016-04-06",
                    "value": 0.0
                },
                {
                    "date": "2016-04-05",
                    "value": 0.0
                },
                {
                    "date": "2016-04-04",
                    "value": 0.0
                },
                {
                    "date": "2016-08-03",
                    "value": 31.4
                },
                {
                    "date": "2016-04-09",
                    "value": 0.0
                },
                {
                    "date": "2016-04-08",
                    "value": 0.0
                },
                {
                    "date": "2016-08-02",
                    "value": 30.5
                },
                {
                    "date": "2016-05-08",
                    "value": 0.0
                },
                {
                    "date": "2016-06-09",
                    "value": 0.0
                },
                {
                    "date": "2016-06-08",
                    "value": 0.0
                },
                {
                    "date": "2016-06-05",
                    "value": 0.0
                },
                {
                    "date": "2016-06-04",
                    "value": 0.0
                },
                {
                    "date": "2016-06-07",
                    "value": 0.0
                },
                {
                    "date": "2016-06-06",
                    "value": 0.0
                },
                {
                    "date": "2016-06-01",
                    "value": 0.0
                },
                {
                    "date": "2016-06-03",
                    "value": 0.0
                },
                {
                    "date": "2016-06-02",
                    "value": 0.0
                },
                {
                    "date": "2016-07-08",
                    "value": 8.7
                },
                {
                    "date": "2016-05-09",
                    "value": 0.0
                },
                {
                    "date": "2016-07-24",
                    "value": 22.8
                },
                {
                    "date": "2016-07-25",
                    "value": 23.7
                },
                {
                    "date": "2016-07-26",
                    "value": 24.6
                },
                {
                    "date": "2016-07-09",
                    "value": 9.5
                },
                {
                    "date": "2016-07-20",
                    "value": 19.3
                },
                {
                    "date": "2016-07-21",
                    "value": 20.2
                },
                {
                    "date": "2016-07-22",
                    "value": 21.1
                },
                {
                    "date": "2016-07-23",
                    "value": 21.9
                },
                {
                    "date": "2016-07-28",
                    "value": 26.3
                },
                {
                    "date": "2016-07-29",
                    "value": 27.1
                },
                {
                    "date": "2016-07-10",
                    "value": 10.4
                },
                {
                    "date": "2016-08-09",
                    "value": 36.2
                },
                {
                    "date": "2016-10-07",
                    "value": 60.3
                },
                {
                    "date": "2016-08-08",
                    "value": 35.4
                },
                {
                    "date": "2016-06-30",
                    "value": 2.3
                },
                {
                    "date": "2016-08-06",
                    "value": 33.8
                },
                {
                    "date": "2016-08-01",
                    "value": 29.7
                },
                {
                    "date": "2016-10-01",
                    "value": 60.3
                }
            ],
            "yield_distributions_profile": [
                {
                    "forecast_date": "2016-06-27",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.9
                        },
                        {
                            "grade": "<45",
                            "value": 0.9
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-26",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.5
                        },
                        {
                            "grade": "<45",
                            "value": 0.5
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-25",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-24",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-23",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-22",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-21",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-20",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-29",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 1.8
                        },
                        {
                            "grade": "<45",
                            "value": 1.8
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-28",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 1.4
                        },
                        {
                            "grade": "<45",
                            "value": 1.4
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-28",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-29",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-22",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.1
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.8
                        },
                        {
                            "grade": "55-75",
                            "value": 21.0
                        },
                        {
                            "grade": ">75",
                            "value": 20.4
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-23",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.2
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.8
                        },
                        {
                            "grade": "55-75",
                            "value": 21.0
                        },
                        {
                            "grade": ">75",
                            "value": 20.4
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-20",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.0
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.0
                        },
                        {
                            "grade": ">75",
                            "value": 20.2
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-21",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.1
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.8
                        },
                        {
                            "grade": "55-75",
                            "value": 21.0
                        },
                        {
                            "grade": ">75",
                            "value": 20.4
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-26",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-27",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-24",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.2
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-25",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-29",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-28",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-31",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-31",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 28.8
                        },
                        {
                            "grade": "<45",
                            "value": 14.3
                        },
                        {
                            "grade": "45-55",
                            "value": 10.9
                        },
                        {
                            "grade": "55-75",
                            "value": 3.3
                        },
                        {
                            "grade": ">75",
                            "value": 0.4
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-30",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 28.0
                        },
                        {
                            "grade": "<45",
                            "value": 14.6
                        },
                        {
                            "grade": "45-55",
                            "value": 10.4
                        },
                        {
                            "grade": "55-75",
                            "value": 2.8
                        },
                        {
                            "grade": ">75",
                            "value": 0.3
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-30",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-30",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 51.2
                        },
                        {
                            "grade": "<45",
                            "value": 6.7
                        },
                        {
                            "grade": "45-55",
                            "value": 15.4
                        },
                        {
                            "grade": "55-75",
                            "value": 18.0
                        },
                        {
                            "grade": ">75",
                            "value": 11.1
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-31",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 51.9
                        },
                        {
                            "grade": "<45",
                            "value": 6.5
                        },
                        {
                            "grade": "45-55",
                            "value": 15.3
                        },
                        {
                            "grade": "55-75",
                            "value": 18.2
                        },
                        {
                            "grade": ">75",
                            "value": 11.9
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-27",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 25.4
                        },
                        {
                            "grade": "<45",
                            "value": 15.2
                        },
                        {
                            "grade": "45-55",
                            "value": 8.3
                        },
                        {
                            "grade": "55-75",
                            "value": 1.8
                        },
                        {
                            "grade": ">75",
                            "value": 0.1
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-30",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-23",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-22",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-24",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-29",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 50.6
                        },
                        {
                            "grade": "<45",
                            "value": 7.0
                        },
                        {
                            "grade": "45-55",
                            "value": 15.6
                        },
                        {
                            "grade": "55-75",
                            "value": 17.7
                        },
                        {
                            "grade": ">75",
                            "value": 10.4
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-28",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 49.9
                        },
                        {
                            "grade": "<45",
                            "value": 7.2
                        },
                        {
                            "grade": "45-55",
                            "value": 15.7
                        },
                        {
                            "grade": "55-75",
                            "value": 17.5
                        },
                        {
                            "grade": ">75",
                            "value": 9.6
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-25",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 47.9
                        },
                        {
                            "grade": "<45",
                            "value": 7.8
                        },
                        {
                            "grade": "45-55",
                            "value": 15.9
                        },
                        {
                            "grade": "55-75",
                            "value": 16.4
                        },
                        {
                            "grade": ">75",
                            "value": 7.9
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-24",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 47.3
                        },
                        {
                            "grade": "<45",
                            "value": 7.9
                        },
                        {
                            "grade": "45-55",
                            "value": 16.0
                        },
                        {
                            "grade": "55-75",
                            "value": 16.0
                        },
                        {
                            "grade": ">75",
                            "value": 7.4
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-27",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 49.3
                        },
                        {
                            "grade": "<45",
                            "value": 7.4
                        },
                        {
                            "grade": "45-55",
                            "value": 15.8
                        },
                        {
                            "grade": "55-75",
                            "value": 17.3
                        },
                        {
                            "grade": ">75",
                            "value": 8.9
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-26",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 48.6
                        },
                        {
                            "grade": "<45",
                            "value": 7.6
                        },
                        {
                            "grade": "45-55",
                            "value": 15.9
                        },
                        {
                            "grade": "55-75",
                            "value": 16.9
                        },
                        {
                            "grade": ">75",
                            "value": 8.4
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-21",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 45.2
                        },
                        {
                            "grade": "<45",
                            "value": 8.5
                        },
                        {
                            "grade": "45-55",
                            "value": 16.1
                        },
                        {
                            "grade": "55-75",
                            "value": 14.6
                        },
                        {
                            "grade": ">75",
                            "value": 6.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-20",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 44.5
                        },
                        {
                            "grade": "<45",
                            "value": 8.7
                        },
                        {
                            "grade": "45-55",
                            "value": 16.1
                        },
                        {
                            "grade": "55-75",
                            "value": 14.1
                        },
                        {
                            "grade": ">75",
                            "value": 5.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-23",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 46.6
                        },
                        {
                            "grade": "<45",
                            "value": 8.1
                        },
                        {
                            "grade": "45-55",
                            "value": 16.0
                        },
                        {
                            "grade": "55-75",
                            "value": 15.5
                        },
                        {
                            "grade": ">75",
                            "value": 6.9
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-22",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 45.9
                        },
                        {
                            "grade": "<45",
                            "value": 8.3
                        },
                        {
                            "grade": "45-55",
                            "value": 16.1
                        },
                        {
                            "grade": "55-75",
                            "value": 15.1
                        },
                        {
                            "grade": ">75",
                            "value": 6.4
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-26",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-27",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-24",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-25",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-08",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 56.5
                        },
                        {
                            "grade": "<45",
                            "value": 5.4
                        },
                        {
                            "grade": "45-55",
                            "value": 14.8
                        },
                        {
                            "grade": "55-75",
                            "value": 20.1
                        },
                        {
                            "grade": ">75",
                            "value": 16.2
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-23",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-20",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-21",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-04",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 54.3
                        },
                        {
                            "grade": "<45",
                            "value": 5.9
                        },
                        {
                            "grade": "45-55",
                            "value": 15.1
                        },
                        {
                            "grade": "55-75",
                            "value": 19.4
                        },
                        {
                            "grade": ">75",
                            "value": 14.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-05",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 54.9
                        },
                        {
                            "grade": "<45",
                            "value": 5.7
                        },
                        {
                            "grade": "45-55",
                            "value": 15.0
                        },
                        {
                            "grade": "55-75",
                            "value": 19.7
                        },
                        {
                            "grade": ">75",
                            "value": 14.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-06",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 55.5
                        },
                        {
                            "grade": "<45",
                            "value": 5.6
                        },
                        {
                            "grade": "45-55",
                            "value": 15.0
                        },
                        {
                            "grade": "55-75",
                            "value": 20.0
                        },
                        {
                            "grade": ">75",
                            "value": 15.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-07",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 56.0
                        },
                        {
                            "grade": "<45",
                            "value": 5.5
                        },
                        {
                            "grade": "45-55",
                            "value": 14.9
                        },
                        {
                            "grade": "55-75",
                            "value": 20.1
                        },
                        {
                            "grade": ">75",
                            "value": 15.6
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-01",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 52.5
                        },
                        {
                            "grade": "<45",
                            "value": 6.3
                        },
                        {
                            "grade": "45-55",
                            "value": 15.2
                        },
                        {
                            "grade": "55-75",
                            "value": 18.4
                        },
                        {
                            "grade": ">75",
                            "value": 12.6
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-28",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-03",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 53.7
                        },
                        {
                            "grade": "<45",
                            "value": 6.0
                        },
                        {
                            "grade": "45-55",
                            "value": 15.1
                        },
                        {
                            "grade": "55-75",
                            "value": 19.0
                        },
                        {
                            "grade": ">75",
                            "value": 13.6
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-08",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-09",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-06",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-22",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-04",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-05",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-02",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-03",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-09",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 56.9
                        },
                        {
                            "grade": "<45",
                            "value": 5.4
                        },
                        {
                            "grade": "45-55",
                            "value": 14.7
                        },
                        {
                            "grade": "55-75",
                            "value": 20.2
                        },
                        {
                            "grade": ">75",
                            "value": 16.7
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-10",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 37.0
                        },
                        {
                            "grade": "<45",
                            "value": 11.3
                        },
                        {
                            "grade": "45-55",
                            "value": 15.0
                        },
                        {
                            "grade": "55-75",
                            "value": 8.9
                        },
                        {
                            "grade": ">75",
                            "value": 1.8
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-11",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 37.8
                        },
                        {
                            "grade": "<45",
                            "value": 11.0
                        },
                        {
                            "grade": "45-55",
                            "value": 15.1
                        },
                        {
                            "grade": "55-75",
                            "value": 9.5
                        },
                        {
                            "grade": ">75",
                            "value": 2.1
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-12",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 38.5
                        },
                        {
                            "grade": "<45",
                            "value": 10.6
                        },
                        {
                            "grade": "45-55",
                            "value": 15.3
                        },
                        {
                            "grade": "55-75",
                            "value": 10.0
                        },
                        {
                            "grade": ">75",
                            "value": 2.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-13",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 39.3
                        },
                        {
                            "grade": "<45",
                            "value": 10.3
                        },
                        {
                            "grade": "45-55",
                            "value": 15.4
                        },
                        {
                            "grade": "55-75",
                            "value": 10.6
                        },
                        {
                            "grade": ">75",
                            "value": 2.8
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-14",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 40.1
                        },
                        {
                            "grade": "<45",
                            "value": 10.0
                        },
                        {
                            "grade": "45-55",
                            "value": 15.6
                        },
                        {
                            "grade": "55-75",
                            "value": 11.2
                        },
                        {
                            "grade": ">75",
                            "value": 3.2
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-15",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 41.2
                        },
                        {
                            "grade": "<45",
                            "value": 9.7
                        },
                        {
                            "grade": "45-55",
                            "value": 15.9
                        },
                        {
                            "grade": "55-75",
                            "value": 12.0
                        },
                        {
                            "grade": ">75",
                            "value": 3.7
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-16",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 42.1
                        },
                        {
                            "grade": "<45",
                            "value": 9.4
                        },
                        {
                            "grade": "45-55",
                            "value": 16.0
                        },
                        {
                            "grade": "55-75",
                            "value": 12.6
                        },
                        {
                            "grade": ">75",
                            "value": 4.2
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-17",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 42.3
                        },
                        {
                            "grade": "<45",
                            "value": 9.3
                        },
                        {
                            "grade": "45-55",
                            "value": 16.1
                        },
                        {
                            "grade": "55-75",
                            "value": 12.7
                        },
                        {
                            "grade": ">75",
                            "value": 4.2
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-18",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 43.0
                        },
                        {
                            "grade": "<45",
                            "value": 9.1
                        },
                        {
                            "grade": "45-55",
                            "value": 16.1
                        },
                        {
                            "grade": "55-75",
                            "value": 13.2
                        },
                        {
                            "grade": ">75",
                            "value": 4.6
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-19",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 43.7
                        },
                        {
                            "grade": "<45",
                            "value": 8.9
                        },
                        {
                            "grade": "45-55",
                            "value": 16.1
                        },
                        {
                            "grade": "55-75",
                            "value": 13.7
                        },
                        {
                            "grade": ">75",
                            "value": 5.1
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-13",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 58.5
                        },
                        {
                            "grade": "<45",
                            "value": 5.0
                        },
                        {
                            "grade": "45-55",
                            "value": 14.4
                        },
                        {
                            "grade": "55-75",
                            "value": 20.5
                        },
                        {
                            "grade": ">75",
                            "value": 18.6
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-12",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 58.2
                        },
                        {
                            "grade": "<45",
                            "value": 5.1
                        },
                        {
                            "grade": "45-55",
                            "value": 14.5
                        },
                        {
                            "grade": "55-75",
                            "value": 20.4
                        },
                        {
                            "grade": ">75",
                            "value": 18.2
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-11",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 57.9
                        },
                        {
                            "grade": "<45",
                            "value": 5.2
                        },
                        {
                            "grade": "45-55",
                            "value": 14.5
                        },
                        {
                            "grade": "55-75",
                            "value": 20.3
                        },
                        {
                            "grade": ">75",
                            "value": 17.9
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-10",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 57.4
                        },
                        {
                            "grade": "<45",
                            "value": 5.3
                        },
                        {
                            "grade": "45-55",
                            "value": 14.6
                        },
                        {
                            "grade": "55-75",
                            "value": 20.2
                        },
                        {
                            "grade": ">75",
                            "value": 17.3
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-17",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 59.6
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 14.2
                        },
                        {
                            "grade": "55-75",
                            "value": 20.8
                        },
                        {
                            "grade": ">75",
                            "value": 19.8
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-16",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 59.5
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 14.3
                        },
                        {
                            "grade": "55-75",
                            "value": 20.8
                        },
                        {
                            "grade": ">75",
                            "value": 19.6
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-15",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 59.2
                        },
                        {
                            "grade": "<45",
                            "value": 4.9
                        },
                        {
                            "grade": "45-55",
                            "value": 14.3
                        },
                        {
                            "grade": "55-75",
                            "value": 20.7
                        },
                        {
                            "grade": ">75",
                            "value": 19.3
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-14",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 58.9
                        },
                        {
                            "grade": "<45",
                            "value": 5.0
                        },
                        {
                            "grade": "45-55",
                            "value": 14.4
                        },
                        {
                            "grade": "55-75",
                            "value": 20.6
                        },
                        {
                            "grade": ">75",
                            "value": 18.9
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-21",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-20",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-19",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 59.9
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 14.0
                        },
                        {
                            "grade": "55-75",
                            "value": 20.9
                        },
                        {
                            "grade": ">75",
                            "value": 20.1
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-18",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 59.7
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 14.1
                        },
                        {
                            "grade": "55-75",
                            "value": 20.9
                        },
                        {
                            "grade": ">75",
                            "value": 19.9
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-25",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-30",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-27",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-26",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-17",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 16.6
                        },
                        {
                            "grade": "<45",
                            "value": 14.7
                        },
                        {
                            "grade": "45-55",
                            "value": 1.9
                        },
                        {
                            "grade": "55-75",
                            "value": 0.1
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-16",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 15.7
                        },
                        {
                            "grade": "<45",
                            "value": 14.3
                        },
                        {
                            "grade": "45-55",
                            "value": 1.4
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-19",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 18.4
                        },
                        {
                            "grade": "<45",
                            "value": 15.4
                        },
                        {
                            "grade": "45-55",
                            "value": 2.8
                        },
                        {
                            "grade": "55-75",
                            "value": 0.2
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-19",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-09-02",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 53.1
                        },
                        {
                            "grade": "<45",
                            "value": 6.2
                        },
                        {
                            "grade": "45-55",
                            "value": 15.2
                        },
                        {
                            "grade": "55-75",
                            "value": 18.7
                        },
                        {
                            "grade": ">75",
                            "value": 13.1
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-18",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 17.5
                        },
                        {
                            "grade": "<45",
                            "value": 15.0
                        },
                        {
                            "grade": "45-55",
                            "value": 2.3
                        },
                        {
                            "grade": "55-75",
                            "value": 0.1
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-15",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-14",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-17",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-29",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-11",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-10",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-13",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-18",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-02",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 3.8
                        },
                        {
                            "grade": "<45",
                            "value": 3.8
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-03",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 4.6
                        },
                        {
                            "grade": "<45",
                            "value": 4.6
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-05",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 33.0
                        },
                        {
                            "grade": "<45",
                            "value": 12.9
                        },
                        {
                            "grade": "45-55",
                            "value": 13.3
                        },
                        {
                            "grade": "55-75",
                            "value": 6.1
                        },
                        {
                            "grade": ">75",
                            "value": 0.8
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-01",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 3.1
                        },
                        {
                            "grade": "<45",
                            "value": 3.1
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-06",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 7.0
                        },
                        {
                            "grade": "<45",
                            "value": 6.9
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-07",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 7.8
                        },
                        {
                            "grade": "<45",
                            "value": 7.8
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-04",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 5.3
                        },
                        {
                            "grade": "<45",
                            "value": 5.3
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-05",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 6.1
                        },
                        {
                            "grade": "<45",
                            "value": 6.1
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-01",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-02",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-03",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-04",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-05",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-06",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-07",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-18",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-19",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-14",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-15",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-16",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-17",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-10",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-11",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-12",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-13",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-16",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-18",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-19",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-16",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-17",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-14",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-15",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-12",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-13",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-10",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-11",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-20",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-21",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-22",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-23",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-24",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-25",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-26",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-12",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-11",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 11.3
                        },
                        {
                            "grade": "<45",
                            "value": 11.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.2
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-07",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 34.6
                        },
                        {
                            "grade": "<45",
                            "value": 12.2
                        },
                        {
                            "grade": "45-55",
                            "value": 14.0
                        },
                        {
                            "grade": "55-75",
                            "value": 7.2
                        },
                        {
                            "grade": ">75",
                            "value": 1.1
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-13",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 13.0
                        },
                        {
                            "grade": "<45",
                            "value": 12.5
                        },
                        {
                            "grade": "45-55",
                            "value": 0.5
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-12",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 12.2
                        },
                        {
                            "grade": "<45",
                            "value": 11.7
                        },
                        {
                            "grade": "45-55",
                            "value": 0.4
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-15",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 14.8
                        },
                        {
                            "grade": "<45",
                            "value": 13.9
                        },
                        {
                            "grade": "45-55",
                            "value": 0.9
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-14",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 13.9
                        },
                        {
                            "grade": "<45",
                            "value": 13.2
                        },
                        {
                            "grade": "45-55",
                            "value": 0.7
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-19",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-18",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-17",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-16",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-15",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-14",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-13",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-12",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-11",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-10",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-03",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-02",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-01",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-04",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 32.2
                        },
                        {
                            "grade": "<45",
                            "value": 13.2
                        },
                        {
                            "grade": "45-55",
                            "value": 12.9
                        },
                        {
                            "grade": "55-75",
                            "value": 5.5
                        },
                        {
                            "grade": ">75",
                            "value": 0.6
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-07",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-06",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-05",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-04",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-03",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 31.4
                        },
                        {
                            "grade": "<45",
                            "value": 13.5
                        },
                        {
                            "grade": "45-55",
                            "value": 12.4
                        },
                        {
                            "grade": "55-75",
                            "value": 5.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-09",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-04-08",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-02",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 30.5
                        },
                        {
                            "grade": "<45",
                            "value": 13.8
                        },
                        {
                            "grade": "45-55",
                            "value": 11.9
                        },
                        {
                            "grade": "55-75",
                            "value": 4.4
                        },
                        {
                            "grade": ">75",
                            "value": 0.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-08",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-09",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-08",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-05",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-04",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-07",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-06",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-01",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-03",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-02",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-08",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 8.7
                        },
                        {
                            "grade": "<45",
                            "value": 8.6
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-05-09",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 0.0
                        },
                        {
                            "grade": "<45",
                            "value": 0.0
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-24",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 22.8
                        },
                        {
                            "grade": "<45",
                            "value": 15.6
                        },
                        {
                            "grade": "45-55",
                            "value": 6.2
                        },
                        {
                            "grade": "55-75",
                            "value": 1.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-25",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 23.7
                        },
                        {
                            "grade": "<45",
                            "value": 15.6
                        },
                        {
                            "grade": "45-55",
                            "value": 6.9
                        },
                        {
                            "grade": "55-75",
                            "value": 1.2
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-26",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 24.6
                        },
                        {
                            "grade": "<45",
                            "value": 15.4
                        },
                        {
                            "grade": "45-55",
                            "value": 7.6
                        },
                        {
                            "grade": "55-75",
                            "value": 1.5
                        },
                        {
                            "grade": ">75",
                            "value": 0.1
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-09",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 9.5
                        },
                        {
                            "grade": "<45",
                            "value": 9.5
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-20",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 19.3
                        },
                        {
                            "grade": "<45",
                            "value": 15.8
                        },
                        {
                            "grade": "45-55",
                            "value": 3.3
                        },
                        {
                            "grade": "55-75",
                            "value": 0.2
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-21",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 20.2
                        },
                        {
                            "grade": "<45",
                            "value": 15.8
                        },
                        {
                            "grade": "45-55",
                            "value": 4.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.4
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-22",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 21.1
                        },
                        {
                            "grade": "<45",
                            "value": 15.7
                        },
                        {
                            "grade": "45-55",
                            "value": 4.7
                        },
                        {
                            "grade": "55-75",
                            "value": 0.6
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-23",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 21.9
                        },
                        {
                            "grade": "<45",
                            "value": 15.7
                        },
                        {
                            "grade": "45-55",
                            "value": 5.5
                        },
                        {
                            "grade": "55-75",
                            "value": 0.8
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-28",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 26.3
                        },
                        {
                            "grade": "<45",
                            "value": 15.0
                        },
                        {
                            "grade": "45-55",
                            "value": 9.0
                        },
                        {
                            "grade": "55-75",
                            "value": 2.2
                        },
                        {
                            "grade": ">75",
                            "value": 0.2
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-29",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 27.1
                        },
                        {
                            "grade": "<45",
                            "value": 14.8
                        },
                        {
                            "grade": "45-55",
                            "value": 9.7
                        },
                        {
                            "grade": "55-75",
                            "value": 2.5
                        },
                        {
                            "grade": ">75",
                            "value": 0.2
                        }
                    ]
                },
                {
                    "forecast_date": "2016-07-10",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 10.4
                        },
                        {
                            "grade": "<45",
                            "value": 10.3
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-09",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 36.2
                        },
                        {
                            "grade": "<45",
                            "value": 11.6
                        },
                        {
                            "grade": "45-55",
                            "value": 14.8
                        },
                        {
                            "grade": "55-75",
                            "value": 8.3
                        },
                        {
                            "grade": ">75",
                            "value": 1.4
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-07",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-08",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 35.4
                        },
                        {
                            "grade": "<45",
                            "value": 11.9
                        },
                        {
                            "grade": "45-55",
                            "value": 14.4
                        },
                        {
                            "grade": "55-75",
                            "value": 7.7
                        },
                        {
                            "grade": ">75",
                            "value": 1.2
                        }
                    ]
                },
                {
                    "forecast_date": "2016-06-30",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 2.3
                        },
                        {
                            "grade": "<45",
                            "value": 2.3
                        },
                        {
                            "grade": "45-55",
                            "value": 0.0
                        },
                        {
                            "grade": "55-75",
                            "value": 0.0
                        },
                        {
                            "grade": ">75",
                            "value": 0.0
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-06",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 33.8
                        },
                        {
                            "grade": "<45",
                            "value": 12.6
                        },
                        {
                            "grade": "45-55",
                            "value": 13.7
                        },
                        {
                            "grade": "55-75",
                            "value": 6.6
                        },
                        {
                            "grade": ">75",
                            "value": 0.9
                        }
                    ]
                },
                {
                    "forecast_date": "2016-08-01",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 29.7
                        },
                        {
                            "grade": "<45",
                            "value": 14.0
                        },
                        {
                            "grade": "45-55",
                            "value": 11.4
                        },
                        {
                            "grade": "55-75",
                            "value": 3.9
                        },
                        {
                            "grade": ">75",
                            "value": 0.4
                        }
                    ]
                },
                {
                    "forecast_date": "2016-10-01",
                    "forecasts": [
                        {
                            "grade": "total",
                            "value": 60.3
                        },
                        {
                            "grade": "<45",
                            "value": 4.8
                        },
                        {
                            "grade": "45-55",
                            "value": 13.9
                        },
                        {
                            "grade": "55-75",
                            "value": 21.1
                        },
                        {
                            "grade": ">75",
                            "value": 20.5
                        }
                    ]
                }
            ]
        };
        getExtremeDates($scope.data)
    }
})(angular);