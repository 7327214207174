(function (angular) {
    'use strict';
    angular.module('PotatoApp')
        .controller('PotatoPlantingUpdateController', ['$scope', '$mdDialog', '$khMessage', '$filter', 'plot_summary',
            'PotatoPlotOperations', PotatoPlantingUpdateController]);

    function PotatoPlantingUpdateController($scope, $mdDialog, $khMessage, $filter, plot_summary,
        PotatoPlotOperations) {

        var vm = this;
        var parentScope = $scope;
        $scope.plot_slug = plot_summary.plot_slug;
        $scope.planting_date_form = potatoPlantingForm();
        $scope.plantingDetail = {};
        $scope.submitPlantingUpdate = submitPlantingUpdate;

        function potatoPlantingForm() {
            return [
                {
                    className: 'flex-100',
                    key: 'plantingDate',
                    type: 'datepicker',
                    defaultValue: new Date(plot_summary.planting_date_string),
                    templateOptions: {
                        label: 'Planting date (first)',
                        required: true,
                    }
                }
            ];
        }

        function submitPlantingUpdate(plot_slug, formData) {
            var planting_date = moment(formData.plantingDate).add(12, 'h').hour(12).format();
            PotatoPlotOperations.query({slug: plot_slug}, function(success) {
                var plantingSubmission = success['results'][0];
                var plot_id = plantingSubmission['id'];
                delete plantingSubmission['id'];
                plantingSubmission['actual_planting_date'] = planting_date;
                if (plantingSubmission['harvest']['date'] === null && plantingSubmission['harvest']['yield'] === null){
                    delete plantingSubmission['harvest'];
                }
                PotatoPlotOperations.update({plot_id: plot_id}, plantingSubmission, function(success){
                    success.plot_slug = plot_slug;
                    success.planting_date = formData.plantingDate;
                    success.planting_date_string = moment(formData.plantingDate).format('DD MMM YYYY');
                    success.planting_status_description = 'Record';
                    $mdDialog.hide(success);
                }, function (error) {
                    $khMessage.show('Failed to record planting details', 5000);
                });
            }, function (error) {
                $khMessage.show('Failed to get Plot', 5000);
            });
        }

    }

})(angular);
