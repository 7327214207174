(function (angular) {
    "use strict";
    angular.module('PotatoApp')
        .factory('CanopyCoverFactory', ["$resource", CanopyCoverFactory]);

    function CanopyCoverFactory($resource) {
        var url = "/api/potato/canopy-summary/";
        var queryParamDict = {
            farm: '@farm'
        };

        return $resource(url, {},
            {
                query: {
                    params: queryParamDict,
                    url: url,
                    isArray: false
                },
                get: {
                    params: {field_plot_crop_slug: '@field_plot_crop_slug'},
                    url: url + ':field_plot_crop_slug/',
                    method: 'GET'
                }
            }
        );
    }

})(angular);