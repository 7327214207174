(function (angular) {
    "use strict";
    angular.module("PotatoApp")
        .factory("PlantingProgressFactory", ["$resource", PlantingProgressFactory]);

    function PlantingProgressFactory($resource) {
        var url = "/api/v1.0/farm-reports/planting-progress/";
        var queryParamDict = {
            farm: "@farm",
            numberofseasons: "@numberofseasons"
        };

        return $resource(url, {},
            {
                get: {
                    params: queryParamDict,
                    url: url
                }
            }
        );
    }

})(angular);




