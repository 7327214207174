(function (angular) {
    "use strict";
    angular.module("PotatoApp")
        .factory("PotatoPlantingUpdateBuilder", ["DateTimeHelperFactory", PlantingUpdateBuilderFactory]);

    function PlantingUpdateBuilderFactory(DateTimeHelperFactory) {
        var directory = {
            create: createPlantingSubmission
        };

        function createPlantingSubmission(plot_slug, formData, plot_area, season) {
            var planting_date = moment(formData.plantingDate).add(12, "h").hour(12).format();

            return {
                "growing_season": season,
                "fieldplotcrop": plot_slug,
                "area_of_operation": plot_area,
                "total_area": plot_area,
                "status": true,
                "operation_type": "Planting",
                "operation": {
                    "type_of_sowing": "Planting",
                    "operation_details": getOperationDetails(plot_slug, planting_date),
                },
                "products": getProductDetails(formData),
                "hideProducts": false,
                "reason": "",
                "notes": "",
                "equipment_details": [],
                "cost_details": [],
                "attachments": []
            };
        }

        function getOperationDetails(plotSlug, plantingDate) {
            var operation_details = [
                {
                    "start_datetime": plantingDate,
                    "duration": null,
                    "percent_completed": 100,
                    "done_by": []
                }
            ];
            return operation_details;
        }

        function getProductDetails(formData) {
            var productDetails = [
                {
                    "product": formData.product
                }
            ];
            if (formData.hasOwnProperty("seedBatch")) {
                productDetails[0]["batch"] = formData.seedBatch;
            }
            return productDetails;
        }

        return directory;

    }

})(angular);